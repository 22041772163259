<template>
  <v-row justify="center">
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="1000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Visual field for contacts selection number -->
    <div class="w-100 my-2 mx-3">
      <div class="d-flex flex-row align-baseline justify-space-around my-1 w-100">
        <v-text-field v-model="selected_length" :label="''" class="small-font lc-input-fields" hide-details dense solo disabled>
          <v-icon slot="append" color="bssecondary2" dense small @click="show = true">mdi-arrow-right-bold-circle</v-icon>
          <v-tooltip color="bssecondary2" bottom slot="append">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
            </template>
            <span>Upload & Select contacts for batch call</span>
          </v-tooltip>
        </v-text-field>
      </div>
    </div>

    <!-- main dialog box -->
    <v-dialog :value="show" persistent fullscreen hide-overlay transition="dialog-bottom-transition" class="rounded-0">
      <v-card>
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Manage & Select Contacts</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn small icon dark @click="show = false">
              <v-icon class="mr-2 pa-2" color="white" dense small @click="show = false">mdi-checkbox-marked-outline</v-icon>
            </v-btn>
            <v-btn small icon dark @click="(show = false), (selected = [])">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <div class="d-flex flex-row justify-center align-center small-font" style="width: 100%">
          <div class="w-100">
            <v-row class="mt-0">
              <v-col cols="12">
                <v-sheet color="white" elevation="3" rounded class="my-1 mx-4">
                  <template>
                    <v-data-table
                      v-model="selected"
                      :headers="headers"
                      :items="contacts"
                      :options.sync="options"
                      :server-items-length="contacts_length"
                      item-key="id"
                      class="rounded-6 datatable-height"
                      :show-select="true"
                      :single-select="singleSelect"
                      :show-expand="false"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      fixed-header
                      dense
                      :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 500] }"
                      :loading="table_loading"
                      elevation="10"
                    >
                      <template v-slot:top>
                        <!-- History row -->
                        <v-row class="px-4 align-baseline justify-center btns-div" no-gutters>
                          <v-col cols="12" md="12" class="mx-0 px-0mr-0 py-0">
                            <h4 class="my-2 mx-0 px-0 text-left w-100 d-flex flex-row align-baseline justify-space-between">
                              <span>Contacts</span>

                              <div>
                                <batch-contacts-basic-filters-dialog @filters-updated="(v) => (filters = v)"></batch-contacts-basic-filters-dialog>
                                <v-btn color="white" class="small-font ml-1 my-1" style="text-transform: capitalize" @click="loadContacts()" small elevation="2">
                                  <v-icon class="mr-1" small>mdi-refresh</v-icon>
                                  Refresh
                                </v-btn>

                                <v-btn color="white" class="small-font ml-1 my-1" style="text-transform: capitalize" @click="selected = []" small elevation="2">
                                  <v-icon class="mr-1" small>mdi-select-off</v-icon>
                                  Deselect All {{ selected.length ? `(${selected.length})` : "" }}
                                </v-btn>
                                <v-btn color="white" class="small-font ml-1 my-1" style="text-transform: capitalize" @click="open_contact_dialog({})" small elevation="2">
                                  <v-icon class="mr-1" small>mdi-account-plus</v-icon>
                                  Add Contact
                                </v-btn>
                                <batch-contacts-add-dialog
                                  :show="show_contact_dialog"
                                  :contact="dialog_contact"
                                  :contacts="contacts"
                                  @dialog-toggled="(v) => (show_contact_dialog = v)"
                                  @contact-updated="contact_updated"
                                ></batch-contacts-add-dialog>
                                <batch-contacts-upload-dialog @batch-uploaded="batch_uploaded"></batch-contacts-upload-dialog>
                              </div>
                            </h4>
                          </v-col>
                        </v-row>
                      </template>

                      <!-- Date slot -->
                      <template v-slot:[`item.updatedAt`]="{ item }">
                        {{ format_date(item.updatedAt) }}
                      </template>

                      <!-- Actions slot -->
                      <template v-slot:[`item.actions`]="{ item }">
                        <!-- Load item -->
                        <v-tooltip color="bssecondary2" left :open-delay="50">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" small class="mr-2" @click="open_contact_dialog(item)" v-on="on">mdi-account-edit</v-icon>
                          </template>
                          <span>Edit Contact</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </template>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BatchContactsUploadDialog from "./BatchContactsUploadDialog.vue";
import BatchContactsAddDialog from "./BatchContactsAddDialog.vue";
import BatchContactsBasicFiltersDialog from "./BatchContactsBasicFiltersDialog.vue";
export default {
  name: "BatchContactsDialog",
  props: [],
  components: {
    BatchContactsUploadDialog,
    BatchContactsAddDialog,
    BatchContactsBasicFiltersDialog,
  },
  data: () => {
    return {
      show: false,

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      // table fields
      contacts: [],
      contacts_length: 0,
      headers: [
        { text: "Firstname", value: "fname", align: "start", sortable: true },
        { text: "Lastname", value: "lname", align: "start", sortable: true },
        { text: "Phone", value: "phone_number", align: "start", sortable: true },
        { text: "Batch Name", value: "batch.name", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      options: {
        page: 1,
        itemsPerPage: 50,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      expanded: [],
      singleExpand: false,
      singleSelect: false,
      selected: [],
      table_loading: false,
      requestSent: false,
      filters: {},

      // add-update contact dialog fields
      dialog_contact: {},
      show_contact_dialog: false,
    };
  },
  computed: {
    selected_length() {
      return `${this.selected.length} contacts selected`;
    },
  },
  watch: {
    options() {
      this.loadContacts();
    },
    selected: {
      handler(newval) {
        console.log("selected contacts updated in child");
        this.$emit("selected-contacts-updated", newval);
      },
      deep: true,
    },
    filters: {
      handler() {
        this.loadContacts();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.loadContacts();
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "History could not be loaded: check your internet connection");
      }
    },
    //   copy results to clipboard
    async copyResult() {
      try {
        if (!this.text) return;
        await navigator.clipboard.writeText(this.text);
        this.snackbarShow(true, "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    // show/unshow snakbar => mostly errors
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    update_snackbar(v) {
      this.showSnackbar = v;
    },

    // table functions
    async loadContacts() {
      try {
        if (this.table_loading) return;
        this.table_loading = true;
        console.log(this.filters);
        let res = await this.axios.post("/api/voicecalling/getcontacts", { options: this.options, filters: this.filters });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.contacts = res.data.contacts;
        this.contacts_length = res.data.count;

      } catch (error) {
        this.table_loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", "Contacts could not be loaded: check your internet connection");
      }
    },

    // contact dialog
    open_contact_dialog(contact) {
      if (!contact.batch) contact.batch = { id: null, name: null };
      this.dialog_contact = contact;
      this.show_contact_dialog = true;
    },

    async contact_updated(contact) {
      await this.loadContacts();
      this.dialog_contact = {};
      this.show_contact_dialog = false;
    },
    async batch_uploaded(contact) {
      await this.loadContacts();
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
.read-mode-toolbar {
  height: 50px;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 400;
  font-size: 16px !important;
}

.small-font * {
  font-weight: 500;
  font-size: 14px !important;
}
.w-100 {
  width: 100%;
  max-width: 100%;
}

::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}
.datatable-height {
  height: 100%;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .btns-div .v-btn {
  width: 150px;
}
</style>
