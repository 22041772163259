<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent app expand-on-hover width="300px">
    <v-list-item>
      <v-icon @click.stop="mini = !mini">mdi-menu</v-icon>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense shaped>
      <template v-for="(item, index) in links">
        <template v-if="item.items.length">
          <template v-if="item.permission.includes($store.getters.getUser.group)">
            <v-list-group :key="item.title" v-model="item.active" :prepend-icon="item.action">
              <!-- <v-list-group :key="item.title" v-model="item.active" :prepend-icon="item.action"> -->
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="small-font">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:prependIcon>
                <v-icon color="#001536">{{ item.icon }}</v-icon>
              </template>

              <template v-for="(child, i) in item.items">
                <v-list-item :key="child.title + i" v-if="child.permissions.includes($store.getters.getUser.group)" :to="child.to">
                  <v-list-item-icon>
                    <v-icon color="#001536">{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="small-font">{{ child.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
            <v-divider v-if="item.sectionEnd" :key="index + 100" class="my-1"></v-divider>
          </template>
        </template>

        <template v-else>
          <!-- <v-list-group :key="item.title" v-model="item.active" :prepend-icon="item.action" v-if="item.permission.includes($store.getters.getUser.group)"> -->

          <v-list-item v-if="item.permission.includes($store.getters.getUser.group)" :to="item.to" :key="index">
            <!-- <v-list-item :to="item.to" :key="index"> -->
            <v-list-item-icon>
              <v-icon color="#001536">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="small-font">{{ item.title }}</v-list-item-title>
            <v-divider v-if="item.sectionEnd" :key="index + 100" class="my-1"></v-divider>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      mini: true,
      links: [
        // user routes
        {
          title: "Text Agents",
          icon: "mdi-comment-multiple-outline",
          sectionEnd: true,
          permission: ["admin", "user"],
          items: [
            { title: "Chatbots", to: { name: "Chatbots" }, icon: "mdi-briefcase-edit-outline", permissions: ["admin", "user"] },
            { title: "All Chatbots (Admin)", to: "/dashboard/admin/chatbots", icon: "mdi-monitor-account", permissions: ["admin"] },
            { title: "Global Chatbot (Admin)", icon: "mdi-forum", permissions: ["admin"], to: { name: "ChatDemo" } },
          ],
        },
        {
          title: "Voice Agents",
          icon: "mdi-phone",
          sectionEnd: true,
          permission: ["admin", "calling_demo_user"],
          items: [
            { title: "Single Call Demo", to: { name: "VoiceDemo" }, icon: "mdi-phone-outgoing", permissions: ["admin", "calling_demo_user"] },
            { title: "Single Call (Admin)", icon: "mdi-phone-outgoing", permissions: ["admin"], to: { name: "VoiceDemoAdmin" } },
            { title: "Batch Calls (Admin)", icon: "mdi-phone-voip", permissions: ["admin"], to: { name: "BatchCallDemoAdmin" } },
            { title: "Call Logs", icon: "mdi-phone-log", permissions: ["admin"], to: { name: "CallLogs" } },
          ],
        },

        // {
        //   title: "Subscriptions",
        //   icon: "mdi-account-cash-outline",
        //   sectionEnd: true,
        //   permission: ["admin", "user"],
        //   items: [
        //     { title: "Status", to: { name: "ViewStatus" }, icon: "mdi-card-account-details-outline", permissions: ["admin", "user"] },
        //     { title: "Manage", to: "/dashboard/user/subscriptions/update", icon: "mdi-briefcase-edit-outline", permissions: ["admin", "user"] },
        //   ],
        // },

        // admin routes
        // {
        //   title: "Admin Chatbots",
        //   icon: "mdi-shield-half-full",
        //   sectionEnd: true,
        //   permission: ["admin"],
        //   items: [],
        // },
        {
          title: "Admin",
          icon: "mdi-shield-half-full",
          // sectionEnd: true,
          permission: ["admin"],
          items: [
            { title: "Users", to: "/dashboard/users/manageusers/changeusersettings", icon: "mdi-account-multiple-plus", permissions: ["admin"] },
            { title: "Add New", to: "/dashboard/admin/subscriptions/create", icon: "mdi-folder-plus-outline", permissions: ["admin"] },
            { title: "Update", to: "/dashboard/admin/subscriptions/update", icon: "mdi-briefcase-edit-outline", permissions: ["admin"] },
            { title: "Delete", to: "/dashboard/admin/subscriptions/delete", icon: "mdi-delete-empty-outline", permissions: ["admin"] },
            { title: "Buy Sessions", to: "/dashboard/admin/subscriptions/buy", icon: "mdi-cart-plus", permissions: ["admin"] },
          ],
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped>
.group-heading {
  /* background-color: #dfedff; */
  /* margin-bottom: 5px; */
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-heading-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.group-heading-text {
  font-weight: 700 !important;
}
.v-icon.v-icon {
  color: black;
}
::v-deep .v-label,
::v-deep .small-font,
::v-deep .small-font *,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
