<template>
  <v-row class="justify-center ma-0">
    <v-overlay :value="loading" z-index="1000">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-2 pa-0">
      <v-card class="px-0 py-5 ma-0 d-flex flex-column justify-space-between align-center">
        <v-row class="w-100 pa-0 ma-0">
          <v-col cols="12" sm="12" md="12" class="d-flex justify-end align-end">
            <v-btn small color="primary" dark text outlined class="py-2 rounded-0 text-capitalize " @click="initialize()">
              <v-icon small>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </v-col>
        </v-row>

        <!-- Message Display-->
        <div class="messages-section w-100 d-flex flex-column justify-start align-center">
          <v-card class="w-50" flat>
            <!-- <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <template>
                    <!-- USER PLAN UPDATE -->
                    <template>
                      <v-col cols="12" sm="12" class="pb-0">
                        <h4 class="pb-0">Subscription Details</h4>
                      </v-col>

                      <v-col cols="12" class="py-5 px-0 d-flex flex-column align-center justify-start">
                        <template v-if="user">
                          <v-simple-table dense class="w-100 px-0">
                            <template v-slot:default>
                              <!-- <thead>
                                <tr>
                                  <th class="text-left">Name</th>
                                  <th class="text-left">Calories</th>
                                </tr>
                              </thead> -->
                              <tbody>
                                <template v-for="data_object of user_data">
                                  <tr v-for="[key, value] of Object.entries(data_object)" :key="key">
                                    <td class="bs-small-bold-text">{{ format_keys(key) }}</td>
                                    <td class="bs-small-bold-text">{{ format_values(key, value) }}</td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </template>
                        <template v-else>
                          <div class="bs-small-bold-text py-8">
                            {{ busy ? "Loading subscription status" : "No user found for this subscription" }}
                          </div>
                        </template>
                      </v-col>
                    </template>
                  </template>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "UserSubStatus",

  data: () => ({
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",
    busy: false,
    user: null,

    key_map: {
      active: { show: true, type: "boolean", text: "Subscription Status" },
      session_limit: { show: true, type: "integer", text: "Plan Session Limit (monthly)" },
      page_limit: { show: true, type: "integer", text: "Plan Page Limit" },
      base_sessions: { show: true, type: "integer", text: "Remaining Sessions (monthly)" },
      base_sessions_exp: { show: true, type: "date", text: "Remaining Sessions Validity" },
      addl_sessions: { show: true, type: "integer", text: "Additional Sessions" },
      addl_session_exp: { show: true, type: "date", text: "Additional Sessions Validity" },
      chatbot_count: { show: true, type: "integer", text: "Active Chatbots" },
      current_pages: { show: true, type: "integer", text: "Uploaded Data (pages)" },
      product_name: { show: true, type: "string", text: "Subscription Plan" },
    },
  }),
  computed: {
    loading() {
      return this.busy;
    },
    user_data() {
      const ud = [];

      if (this.user) {
        ud.push(this.user.subscription);
        ud.push(this.user.subscription.plan);
        ud.push(this.user.sessions);
        ud.push({ chatbot_count: this.user.chatbot_count, current_pages: this.user.current_pages });
        delete ud[0].plan;
        console.log(ud);
      }
      return ud;
    },
  },
  watch: {},

  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_user_data();
      } catch (error) {
        console.log(error);
      }
    },

    async get_user_data() {
      try {
        this.busy = true;

        let res = await this.axios.get("/api/subscriptions/getusersubscriptiondata");
        this.busy = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.user = res.data.user;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "User subscription data could not be fetched");
      }
    },

    format_keys(key) {
      return this.key_map[key] ? this.key_map[key].text : "";
    },
    format_values(key, value) {
      const t = this.key_map[key] ? this.key_map[key].type : null;
      if (t == "boolean") return value ? "Active" : "Inactive";
      else if (t == "date") return value ? moment(value).format("DD MMM YY") : "";

      return value;
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    format_key(key) {
      if (key) return key.replace(/_/gm, " ").trim() + ": ";
      return key;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
@media (max-width: 768px) {
  .w-50 {
    width: 100% !important;
  }
}
.messages-section {
  height: 90%;
}

.messages-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.message {
  border-bottom: 1px solid #e4e3e3;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.messags_text {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.setting-icons {
  cursor: pointer;
}

/* scroll styling */
#message-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.user_bg {
  background-color: #f8f8f8;
}
::v-deep .message_input input {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
}
.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

::v-deep .dialog-select .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-select .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}
::v-deep .dialog-file-input .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-file-input .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-textfield input,
::v-deep .dialog-textfield .v-label {
  font-size: 13px;

  /* Add more CSS styles as needed */
}

::v-deep .script-textarea {
  font-size: 12px;
  font-weight: 500;
  color: red !important;
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: #4a4a4a !important;
  font-weight: 700;
}

.field_icons {
  cursor: pointer;
}
.color_pickers {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

::v-deep .v-input--is-disabled input {
  color: darkgray;
  cursor: not-allowed;
}
input:disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}

.fields-info-tooltip-text {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.fields-info-tooltip-icon {
  /* color: #361f00; */
}
.stripe-div {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 4px;
}

.bs-small-bold-text {
  font-size: 13px !important;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
