<template>
  <span>
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="2000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- the visible button in the component -->
    <v-btn color="white" class="small-font ml-1" style="text-transform: capitalize" @click="show = !show" small elevation="2">
      <v-icon class="mr-1" small>mdi-filter</v-icon>
      Filters {{ active_filters ? `(${active_filters})` : "" }}
    </v-btn>

    <!-- Dialog box with options and triggers-->
    <v-dialog :value="show" max-width="550" persistent hide-overlay transition="dialog-bottom-transition" class="rounded-0 pa-2">
      <v-card>
        <!-- toolbar section -->
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Basic Filters</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn small icon dark @click="show = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <!-- content section -->
        <div class="w-100 pa-3">
          <div class="d-flex flex-column justify-center align-center w-100">
            <v-sheet color="bsprimary2" elevation="3" rounded class="ma-1 py-4 colored-sheet w-100">
              <v-row justify="center">
                <v-col cols="12">
                  <!-- Text Field -->
                  <div class="d-flex flex-column align-left justify-center px-4">
                    <v-text-field v-model="filters.phone_number" label="Phone Number" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter complete or partial phone number to filter calls.</span>
                      </v-tooltip>
                    </v-text-field>

                    <v-text-field v-model="filters.name" label="Name" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter complete or partial Name to filter calls</span>
                      </v-tooltip>
                    </v-text-field>

                    <v-autocomplete
                      v-model="filters.campaign_id"
                      :items="campaigns"
                      item-text="name"
                      item-value="id"
                      :loading="campaign_loading"
                      label="Select campaign"
                      class="small-font lc-input-fields"
                      hide-details
                      dense
                      solo
                    >
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small @click.stop="get_campaigns">mdi-refresh</v-icon>
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Select campaign to filter calls.</span>
                      </v-tooltip>
                    </v-autocomplete>
                  </div>

                  <!-- buttons row -->
                  <v-row class="px-4 align-right justify-space-between btns-div" no-gutters>
                    <v-col cols="12" md="12" class="text-right">
                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="reset" small elevation="2" :disabled="loading">
                        <v-icon class="mr-1" small>mdi-redo</v-icon>
                        Reset
                      </v-btn>
                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="set_filters" small elevation="2" :loading="loading" :disabled="loading">
                        <v-icon class="mr-1" small>mdi-filter</v-icon>
                        <span slot="loader">processing...</span>
                        Set Filters
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import _ from "lodash";
export default {
  name: "CallFilterDialog",
  data: () => {
    return {
      show: false,
      filters: {
        name: null,
        phone_number: null,
        campaign_id: null,
      },
      default_filters: {
        name: null,
        phone_number: null,
        campaign_id: null,
      },
      campaigns: [],

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      loading: false,
      campaign_loading: false,
    };
  },
  computed: {
    active_filters() {
      const fs = Object.values(this.filters).filter((v) => v);
      return fs.length;
    },
  },

  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_campaigns();
      } catch (error) {
        console.log(error);
      }
    },
    async get_campaigns() {
      try {
        this.campaign_loading = true;
        const res = await this.axios.get("/api/voicecalling/getusercampaigns");
        this.campaign_loading = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.campaigns = res.data.campaigns;
      } catch (error) {
        this.campaign_loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", "Campaigns could not be fetched - Connection Error");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    reset() {
      this.filters = _.cloneDeep(this.default_filters);
      this.$emit("filters-updated", _.cloneDeep(this.filters));
    },
    async set_filters() {
      this.loading = true;
      await new Promise((r) => setTimeout(r, 300));
      this.$emit("filters-updated", _.cloneDeep(this.filters));
      this.loading = false;
      this.show = false;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .btns-div .v-btn {
  width: 150px;
}
</style>
