<template>
  <v-row class="justify-center my-2 mx-1 pa-0">
    <v-overlay :value="loading" z-index="1000">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
      <v-card class="pa-0 ma-0 d-flex flex-column justify-space-between align-center">
        <v-row class="w-100 pa-0 ma-0">
          <v-col cols="12" sm="12" md="12" class="d-flex justify-end align-end">
            <v-btn
              small
              color="primary"
              dark
              text
              outlined
              class="py-5 rounded-0 text-capitalize"
              v-if="session"
              :to="{ name: 'Sessions', params: { id: session.chatbot_id } }"
            >
              <v-icon small class="mr-1">mdi-keyboard-backspace</v-icon>
              Back
            </v-btn>
            <v-btn small color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="initialize()">
              <v-icon small>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </v-col>
        </v-row>

        <!-- Message Display-->
        <v-row class="messages-section w-100 d-flex flex-column justify-start align-center">
          <v-col cols="12" md="6">
            <v-card class="w-100" flat>
              <!-- <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title> -->
              <v-card-text>
                <v-container>
                  <v-row>
                    <template>
                      <!-- chatbot settings section -->
                      <v-col cols="12" sm="12" class="pb-0">
                        <h4 class="pb-3">Session Data</h4>
                        <v-divider class="my-4"></v-divider>
                      </v-col>

                      <!-- existing chatbot -->
                      <template v-if="session">
                        <!-- <v-card class=" pa-4 elevation-5"> -->
                        <template v-for="[k, v] of Object.entries(session)">
                          <template v-if="!formatting.ignored_fields.includes(k)">
                            <!-- string -->
                            <template v-if="['string', 'number', 'boolean'].includes(type(v))">
                              <v-col cols="6" class="py-1 text-capitalize font-weight-bold" :key="k">{{ format_keys(k) }}</v-col>
                              <v-col cols="6" class="py-1" :key="k + '_v'">{{ format_values(k, v) }}</v-col>
                            </template>

                            <!-- objects -->
                            <template v-else-if="array_check(v)">
                              <v-col cols="6" class="py-1 text-capitalize font-weight-bold" :key="'ar' + k">{{ format_keys(k) }}</v-col>
                              <v-col cols="6" class="py-1" :key="k + 'arv'">{{ format_values(k, v) }}</v-col>
                            </template>

                            <!-- objects -->
                            <template v-else-if="type(v) == 'object'">
                              <template v-for="[ok, ov] of Object.entries(v)">
                                <v-col cols="6" class="py-1 text-capitalize font-weight-bold" :key="ok">{{ format_keys(ok) }}</v-col>
                                <v-col cols="6" class="py-1" :key="ok + '_v'">{{ format_values(ok, ov) }}</v-col>
                              </template>
                            </template>
                          </template>
                        </template>

                        <!-- Contact Request -->
                        <template>
                          <v-col cols="12" class="py-1 text-capitalize font-weight-bold">
                            <v-divider class="my-4"></v-divider>
                          </v-col>

                          <template>
                            <v-col cols="6" class="py-1 text-capitalize font-weight-bold">Contact Request Detected</v-col>
                            <v-col cols="6" class="py-1">{{ session.contact_req ? "Yes" : "No" }}</v-col>
                          </template>
                          <!-- COntact Information -->
                          <template>
                            <v-col cols="6" class="py-1 text-capitalize font-weight-bold">Contact Info Detected</v-col>
                            <v-col cols="6" class="py-1">{{ session.contact_info ? session.contact_info.join(", ") : "No" }}</v-col>
                          </template>
                        </template>

                        <!-- </v-card> -->

                        <!-- session messages-->
                        <v-card class="my-8 elevation-5">
                          <v-col cols="12" sm="12" class="py-0">
                            <h4 class="py-2 text-center">Chat Thread</h4>
                            <v-divider></v-divider>
                          </v-col>
                          <div id="busichat-chat-body">
                            <div id="busichat-chat-messages">
                              <template v-for="(m, i) of session.messages">
                                <v-col cols="12" class="py-3" :key="'message' + i">
                                  <div class="message" :class="{ 'message-sent': m.role == 'user', 'message-received': m.role == 'assistant' }">
                                    <span class="text-capitalize font-weight-bold">{{ m.role }}:</span>
                                    <br />
                                    <span>{{ m.content }}</span>
                                  </div>
                                </v-col>
                              </template>
                            </div>
                          </div>
                        </v-card>
                      </template>
                    </template>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  name: "Session",
  data: () => ({
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    busy: false,
    busy_upload_button: false,

    session: null,
    formatting: {
      ignored_fields: ["id", "chatbot_id", "messages", "createdAt", "updatedAt", "contact_req", "contact_info"],
      dt_fields: ["started", "ended", "last_activity", "createdAt", "updatedAt"],
      user_agent: ["user_agent"],
    },
  }),
  computed: {
    loading() {
      return this.busy;
    },
  },
  watch: {},
  async mounted() {
    console.log("Params: ", this.$route.params);
    this.session = this.$route.params;
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        // await this.get_user_chabot();
      } catch (error) {
        console.log(error);
      }
    },

    async get_user_chabot() {
      try {
        this.busy = true;
        const id = this.$route.params.chatbot_id;
        let res = await this.axios.post("/api/openai/getuserchatbot", { id });
        this.busy = false;
        console.log(res.data.chatbot);
        if (!res.data.success) throw new Error(res.data.message);
        this.chatbot = res.data.chatbot;

        const settings = res.data.chatbot.settings;
        if (settings) {
          if (settings.fields) for (let k of Object.keys(this.settings.fields)) this.settings.fields[k] = settings.fields[k] || "";
          if (settings.style) for (let k of Object.keys(this.settings.style)) this.settings.style[k] = settings.style[k] || "";
          if (settings.custom_fields) this.settings.custom_fields = settings.custom_fields;
        }
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    type(v) {
      return typeof v;
    },
    format_keys(k) {
      return k.replace(/_/gi, " ");
    },
    format_values(k, v) {
      console.log(k, v);
      if (this.formatting.dt_fields.includes(k)) return moment(v).format("DD MMM YYYY HH:mm:ss");
      else if (Array.isArray(v)) return v.join(", ");
      // if (this.formatting.user_agent.includes(k)) return this.formatUserAgent(v);

      return v;
    },

    array_check(v) {
      if (Array.isArray(v)) return true;
      return false;
    },

    formatUserAgent(userAgent) {
      // Regular expressions to extract information from the user agent string
      const browserPattern = /([\w\s]+)(?:\/[\d.]+)?/;
      const osPattern = /\(([\w\s.]+)\)/;
      const devicePattern = /([\w\s]+)/;

      // Extract browser information
      const browserMatch = userAgent.match(browserPattern);
      const browser = browserMatch ? browserMatch[1] : "unknown";

      // Extract operating system information
      const osMatch = userAgent.match(osPattern);
      const os = osMatch ? osMatch[1] : "unknown";

      // Extract device information
      const deviceMatch = userAgent.match(devicePattern);
      const device = deviceMatch ? deviceMatch[1] : "unknown";

      // Format and return the information
      const formattedInfo = `Browser: ${browser} | OS: ${os} | Device: ${device}`;
      return formattedInfo;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
@media (max-width: 768px) {
  .w-50 {
    width: 100% !important;
  }
}

.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}

.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

/* message styling */

.message {
  padding: 0px;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  max-width: 70%;
  width: 70%;
  width: fit-content;
  font-family: inherit;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
  overflow-wrap: anywhere;
}
@media (max-width: 600px) {
  .message {
    width: 90%;
    max-width: 90%;
  }
}

.message-sent {
  background-color: #8348ff;
  color: #fff;
  border-radius: 12px;
  padding: 10px 20px;
  position: relative;
}

.message-sent:before {
  content: "";
  position: absolute;
  /* left: -6%; */
  right: 100%;
  top: 50%;
  border: 10px solid transparent;
  border-right-color: #8348ff;
  transform: translateY(-50%);
}

/* Assistant message bubble */
.message-received {
  background-color: #f1f0f0;
  color: #000;
  border-radius: 12px;
  padding: 10px 20px;
  position: relative;
  margin-left: auto;
}

.message-received:before {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  border: 10px solid transparent;
  border-left-color: #f1f0f0;
  transform: translateY(-50%);
}

.error-message {
  background-color: #f8bfbb;
}
.error-message:before {
  border-left-color: #f8bfbb;
}
.loading-message {
  /* background-color: #a1b7ff; */
  width: 100px;
}
#busichat-chat-body {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  max-height: 500px;
  height: 500px;
}
#busichat-chat-messages {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 10px;
}
</style>
