<template>
  <!-- Data table for contct list: start -->

  <v-row class="py-2">
    <v-col cols="12" class="">
      <v-overlay :value="loading" z-index="1000">
        <v-snackbar v-model="loading" timeout="-1" centered>
          <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
          <span class="py-2 px-2">Processing...</span>
        </v-snackbar>
      </v-overlay>

      <!-- Snackbar for small info alerts -->
      <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
        <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
        <div class="py-1" style="font-size: 13px">
          <div>{{ snackbarText2 }}</div>
        </div>
        <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
      </v-snackbar>

      <!-- users datatable -->
      <v-data-table
        :headers="headers"
        :items="sessions"
        sort-by="id"
        class="mx-0 px-2 v-sheet v-card fill-height"
        dense
        :options.sync="options"
        :server-items-length="sessions_count"
        @click:row="go_to_session"
        :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <!-- add / edit user modal -->
            <v-btn small color="primary" dark text outlined class="pa-5 rounded-0 text-capitalize" :to="{ name: 'Chatbots' }">
              <v-icon class="mr-1" small>mdi-keyboard-backspace</v-icon>
              Back
            </v-btn>
            <v-btn small color="primary" dark text outlined class="pa-5 rounded-0 text-capitalize" @click="initialize()">
              <v-icon small class="mr-1">mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </v-toolbar>
        </template>

        <!-- Actions slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left dense small class="action-menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense flat>
              <v-list-item disabled class="action-list-item" @click="deleteItem(item)">
                <v-list-item-title class="action-list-item-title">
                  <v-icon color="grey" small class="mr-2">mdi-delete</v-icon>
                  Delete Session
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="action-list-item" @click="go_to_session(item)">
                <v-list-item-title class="action-list-item-title">
                  <v-icon color="primary" small class="mr-2">mdi-forum</v-icon>
                  Check Session
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- started slot -->
        <template v-slot:[`item.started`]="{ item }">
          <span>{{ format_datetime(item.started) }}</span>
        </template>

        <!-- last_activity slot -->
        <template v-slot:[`item.last_activity`]="{ item }">
          <span>{{ format_datetime(item.last_activity) }}</span>
        </template>

        <!-- ended slot -->
        <template v-slot:[`item.ended`]="{ item }">
          <span>{{ format_datetime(item.ended) }}</span>
        </template>

        <!-- messages slot -->
        <template v-slot:[`item.messages`]="{ item }">
          <span>{{ item.messages.length }}</span>
        </template>

        <!-- messages slot -->
        <template v-slot:[`item.contact_req`]="{ item }">
          <v-icon small class="mr-1">{{ item.contact_req? "mdi-check":"mdi-close" }} </v-icon>
          <!-- <span>{{ item.contact_req }}</span> -->
        </template>

        <!-- Empty table slot -->
        <template v-slot:no-data>
          <div>No sessions found for this chatbot...</div>
        </template>
      </v-data-table>
      <!-- Data table for contct list: end -->
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  // props: ["users", "usersRequest"],
  data: () => ({
    sessions: [],
    headers: [
      { text: "Session", align: "start", sortable: true, value: "session_id" },
      { text: "Started", align: "start", sortable: true, value: "started" },
      { text: "Last Activity", align: "start", sortable: true, value: "last_activity" },
      { text: "Ended", align: "start", sortable: true, value: "ended" },
      { text: "Messages", align: "center", sortable: true, value: "messages" },
      { text: "Contact Request", align: "center", sortable: false, value: "contact_req" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    options: {
      page: 1,
      itemsPerPage: 20,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    sessions_count: 0,

    // table loading
    errorStatus: "No contacts available, reload?",

    // alerts fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    requestSent: false,

    session: null,
  }),

  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    // new fields
    options() {
      this.get_sessions();
    },
  },

  async mounted() {
    console.log("params: ", this.$route.params);

    await this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      await this.get_sessions();
    },
    async get_sessions() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.post("/api/sessions/getsessions", { chatbot: this.$route.params, options: this.options });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error("Sessions could not be loaded");
        this.sessions = res.data.sessions;
        this.sessions_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "An error occuered while trying to get sessions - kindly check your internet connection");
      }
    },

    async delete_chatbot() {},

    // snackbar and overlay
    setSnackbar(show, title, text) {
      this.showSnackbar = show;
      this.snackbarText = title;
      this.snackbarText2 = text;
    },

    go_to_session(session) {
      try {
        this.$router.push({ name: "Session", params: session });
      } catch (error) {
        console.log(error);
      }
    },

    format_datetime(dt) {
      return moment(dt).format("HH:mm:ss (DD MMM) ");
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.action-list-item {
  font-size: 13px !important;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 16px;
}

.action-list-item-title {
  font-size: 13px !important;
  cursor: pointer;
}

::v-deep .v-data-table tr {
  cursor: pointer;
}
</style>
