<template>
  <div class="d-flex flex-column align-start justify-space-around pb-1">
    <h5 class="my-4 mx-1 text-left">Call Transfers</h5>
    <div v-for="(t, index) of transfers" :key="index" class="d-flex flex-row align-center justify-space-around pb-1 w-100">
      <!-- <v-select v-model="t.key" label="Staff Role" :items="staff_roles" dense small-chips solo class="sr-input-fields mr-1" hide-details> </v-select> -->
      <v-text-field v-model="t.key" label="Name or Department" class="mr-1 small-font sr-input-fields" hide-details dense solo> </v-text-field>
      <v-text-field v-model="t.value" label="Phone number (Intl Format)" class="small-font sr-input-fields" hide-details dense solo> </v-text-field>

      <v-icon color="red" dense small class="mx-1" style="cursor: pointer" @click="remove_range(index)">mdi-playlist-remove</v-icon>
      <v-icon v-if="index == 0" @click="add_item" color="primary" dense small class="mr-1" style="cursor: pointer">mdi-plus</v-icon>

      <div v-else class="mr-5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallTransferForm",
  props: ["transfers"],
  data() {
    return {};
  },
  watch: {
    transfers: {
      deep: true,
      handler(newval) {
        this.update_data();
      },
    },
  },
  mounted() {
    this.update_data();
  },
  methods: {
    update_data() {
      if (!this.transfers.length)
        this.transfers.push({
          key: "default",
          value: "+923217390989", //remove after testing
        });
    },
    add_item() {
      this.transfers.push({ key: "", value: "" });
    },
    remove_range(index) {
      if (this.transfers.length > 1) this.transfers.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .sr-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
}
</style>
