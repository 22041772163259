<template>
  <v-row row class="justify-center my-10 mx-5">
    <v-col cols="12" class="d-flex justify-center">
      <v-card max-width="500px" width="500px" class="pa-5 rounded-0">
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="user.firstName"
            :rules="firstNameRules"
            label="First Name"
            required
            dense
            solo
            clearable
            class="rounded-0"
          ></v-text-field>
          <!-- <v-text-field
            v-model="user.lastName"
            :rules="lastNameRules"
            label="Last Name"
            required
            dense
            solo
            clearable
            class="rounded-0"
          ></v-text-field> -->
          <v-text-field
            v-model="user.email"
            :disabled="true"
            label="Email"
            dense
            solo
            clearable
            class="rounded-0"
          ></v-text-field>
          <!-- <v-select
            dense
            v-model="user.image"
            :items="avatars"
            item-text="name"
            item-value="path"
            label="Select Avatar"
            solo
            class="rounded-0 text-capitalize"
          >
            <template v-slot:selection="{ item }">
              <v-img
                height="100"
                contain
                :src="require('../../../assets/avatars/' + item.path)"
              ></v-img>
            </template>
            <template v-slot:item="{ item }">
              <v-img
                height="100"
                contain
                :src="require('../../../assets/avatars/' + item.path)"
              ></v-img>
            </template>
          </v-select> -->

          <v-col class="d-flex justify-end ma-0 pa-0">
            <!-- <v-btn color="grey" outlined text class="ml-4 rounded-0">
              Cancel
            </v-btn> -->
            <v-btn
              :disabled="!valid || requestSent"
              outlined
              text
              class="ml-4 rounded-0"
              @click="validate"
              align-end
            >
              Save
            </v-btn>
          </v-col>

          <v-col class="d-flex justify-end">
            <!-- <v-btn color="grey" outlined text class="ml-4 rounded-0">
              Cancel
            </v-btn> -->
            <!-- <v-btn
              :disabled="requestSentSub"
              outlined
              text
              class="ml-4 rounded-0"
              @click="confirmCancelSubscription()"
              align-end
              style="color: white; background-color: #eb6d65"
            >
              Cancel Subscription
            </v-btn> -->
          </v-col>

          <v-col cols="12" class="d-flex justify-end mt-2 small-font">{{
            status
          }}</v-col>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    requestSent: false,
    requestSentSub: false,
    status: '',
    firstNameRules: [(v) => !!v || 'First name can not be empty'],
    lastNameRules: [(v) => !!v || 'First name can not be empty'],
    countryRules: [(v) => !!v || 'Country is required'],
    user: {},

    // images
    selected_avatar: '',
    avatars: [
      { name: 'avatar9', path: 'avatar9.png' },
      { name: 'avatar8', path: 'avatar8.png' },
      { name: 'avatar7', path: 'avatar7.png' },
      { name: 'avatar6', path: 'avatar6.png' },
      { name: 'avatar5', path: 'avatar5.png' },
      { name: 'avatar4', path: 'avatar4.png' },
      { name: 'avatar3', path: 'avatar3.png' },
      { name: 'avatar2', path: 'avatar2.png' },
    ],
  }),

  mounted() {
    this.user = Object.assign({}, this.$store.getters.getUser);
    if (!this.user.id) this.status = 'There was an error fetching user data';
  },
  methods: {
    async validate() {
      try {
        let validated = this.$refs.form.validate();
        if (!validated) return (this.status = 'Kindly check all the fields');

        this.requestSent = true;
        this.status = '';

        let res = await this.axios.put('/api/users/user/editself', this.user);
        this.requestSent = false;
        this.status = res.data.message;
        if (!res.data.success) return;
        this.$store.dispatch('setUser', this.user);
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.status =
          'There was an error while updating the user, kindly check the connection.';
      }
    },

    async confirmCancelSubscription() {
      if (confirm('Do you really want to cancel your subscription?')) {
        try {
          this.requestSentSub = true;
          this.status = '';
          let res = await this.axios.post(
            '/api/payments/cancelSubscription',
            this.user
          );
          this.requestSent = false;
          this.status = res.data.message;
          if (!res.data.success) return;
          this.$store.dispatch('setUser', this.user);
        } catch (error) {
          this.requestSent = false;
          console.log(error);
          this.status =
            'There was an error while updating the user, kindly check the connection.';
        }
      }
    },
  },
};
</script>
<style scoped>
* {
  letter-spacing: normal;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
