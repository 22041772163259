<template>
  <div style="width: 100%; height: 100%; background-color: #f2f2f2">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AdmminSubscriptionContainer",
  components: {},
  data() {
    return {};
  },
  computed: {},
  async mounted() {},
};
</script>
