const call_data = {
  campaign_name: {
    value: null,
    include: true,
    show_include: false,
    label: "Enter campaign name",
    info: "Enter name of the campaign you want to send under, this will be used to keep record of sent campaigns.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: null,
    },
  },
  agent_name: {
    value: "Fatima",
    include: true,
    show_include: false,
    label: "Enter name of the AI agent",
    info: "Enter name of the AI agent, this will be used by for personal reference.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  agent_role: {
    value: "AI Assistant",
    include: true,
    show_include: false,
    label: "Enter role of the AI agent",
    info: "Enter role of the AI agent, this will be used by AI Agent for reference & character.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  agent_company_name: {
    value: "FivePearls",
    include: true,
    show_include: false,
    label: "Enter name of the AI agent's company",
    info: "Enter name of the AI agent's, this will be used by AI Agent for personal reference & assosiation.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  first_sentence: {
    value: "Hi, My name is Fatima from FivePearls Property Funds, How are you today?",
    include: true,
    show_include: false,
    label: "Enter First Sentence",
    info: "Enter the first sentence AI Agent will use to start the conversation, example could be a greeting or company specific text.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
};

const call_settings = {
  voice: {
    value: null,
    include: true,
    show_include: false,
    label: "Select Voice (AUS Female)",
    info: "Voice or accent of Ai Voice Agent",
    show_info: true,
    show: true,
    disabled: false,
    type: "text",
    input: "select",
    input_data: {
      items: [],
      item_text: (o) => `${o.name} (${o.description})`,
      item_value: "name",
      default: "",
      multiple: false,
    },
  },
  wait_for_greeting: {
    value: null,
    include: true,
    show_include: false,
    label: "Wait For Greetings (Yes)",
    info: "Instructurs AI Agent whether the AI speak first or wait for someone else to talk?",
    show_info: true,
    show: true,
    disabled: false,
    type: "boolean",
    input: "select",
    input_data: {
      items: [
        { text: "No", value: false },
        { text: "Yes", value: true },
      ],
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },

  interruption_threshold: {
    value: null,
    include: true,
    show_include: false,
    label: "Interruption (100ms)",
    info: "Interruption Tolerance - The more the number, the lesser interruptions but more silent time for agent to hear",
    show_info: true,
    show: true,
    disabled: false,
    type: "number",
    input: "text",
    input_data: {
      default: "",
      min: 0,
      max: 800,
    },
  },
  record: {
    value: null,
    include: true,
    show_include: false,
    label: "Record Voice (Yes)",
    info: "Enable or disable call recording",
    show_info: true,
    show: true,
    disabled: false,
    type: "boolean",
    input: "select",
    input_data: {
      items: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },
  language: {
    value: null,
    include: true,
    show_include: false,
    label: "Select Language (Eng)",
    info: "Language AI voice Agent will speak in",
    show_info: true,
    show: true,
    disabled: false,
    type: "text",
    input: "select",
    input_data: {
      items: [
        { text: "English", value: "ENG" },
        { text: "Spanish", value: "ESP" },
        { text: "French", value: "FRE" },
        { text: "Polish", value: "POL" },
      ],
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },
  max_duration: {
    value: null,
    include: true,
    show_include: false,
    label: "Max Duration (10 mins)",
    info: "Maximum duration of a call after which call will end",
    show_info: true,
    show: true,
    disabled: false,
    type: "number",
    input: "text",
    input_data: {
      default: "",
      min: 1,
      max: 30,
    },
  },
};

module.exports = {
  call_data,
  call_settings,
};
