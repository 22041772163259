<template>
  <!-- Data table for contct list: start -->

  <v-row class="py-5">
    <v-col cols="12">
      <v-overlay :value="loading" z-index="1000">
        <v-snackbar v-model="loading" timeout="-1" centered>
          <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
          <span class="py-2 px-2">Processing...</span>
        </v-snackbar>
      </v-overlay>
      <!-- Snackbar for small info alerts -->
      <v-snackbar
        :value="showSnackbar"
        timeout="-1"
        :vertical="true"
        app
        bottom
        right
        style="opacity: 0.9; z-index: 2005"
        content-class="main-snackbar-content d-flex flex-column align-start justify-start"
      >
        <div class="py-0" style="font-size: 15px">
          <b>{{ snackbarText }}</b>
        </div>
        <div class="py-1 text-capitalize" style="font-size: 13px">
          <template v-if="typeof snackbarText2 == 'string'">
            <span>{{ snackbarText2 }}</span>
          </template>
          <template v-else>
            <template v-for="(value, key) in snackbarText2">
              <div v-if="!['errors', 'failedDetails', 'success_ids'].includes(key)" :key="key">{{ key | removeUnderscore }}: {{ value }}</div>
            </template>
          </template>
        </div>
        <template v-slot:action="">
          <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

      <!-- Edit Dialog Box: start -->
      <v-dialog v-model="dialog" max-width="500px" flat>
        <v-card>
          <v-card-title class="text-center">
            <h4 class="text-center" style="width: 100%">{{ formTitle }}</h4>
          </v-card-title>

          <v-card-text class="pb-0 mb-0">
            <v-container>
              <v-row>
                <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-start" style="flex-wrap: wrap; width: 100%">
                  <v-col cols="12" class="py-0">
                    <v-text-field v-model="editedItem.firstName" :rules="firstNameRules" required label="Full Name" dense solo></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" class="py-0">
                    <v-text-field v-model="editedItem.lastName" :rules="lastNameRules" label="Last Name" dense solo
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12" class="py-0">
                    <v-text-field v-model="editedItem.email" :disabled="editedIndex !== -1" :rules="emailRules" label="Email" dense solo></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" class="py-0">
                    <v-select
                      v-model="editedItem.verified"
                      :items="adminVerifiedOptions"
                      single-line
                      :rules="adminVerifiedRules"
                      label="Email Verified"
                      class="rounded-0 text-capitalize"
                      dense
                      solo
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" class="py-0">
                    <v-select
                      v-model="editedItem.group"
                      :items="groups"
                      single-line
                      :rules="groupRules"
                      label="Select Group"
                      class="rounded-0 text-capitalize"
                      dense
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-select
                      v-model="editedItem.type"
                      :items="usertypes"
                      single-line
                      label="Select User Type"
                      class="rounded-0 text-capitalize"
                      dense
                      solo
                    ></v-select>
                  </v-col>

                  <!-- <v-col cols="12" class="py-0">
                    <v-text-field
                      v-if="editedIndex === -1"
                      v-model="password"
                      :rules="passwordRules"
                      label="Password"
                      type="password"
                      dense
                      solo
                    
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col cols="12" class="py-0">
                    <v-text-field
                      v-if="editedIndex === -1"
                      v-model="repeatPassword"
                      :rules="repeatPasswordRules"
                      label="Repeat Password"
                      type="password"
                      dense
                      solo
                    
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col cols="12" class="py-0">
                    <v-text-field v-model="editedItem.credit" label="Credit / Tokens" type="number" dense solo></v-text-field>
                  </v-col> -->
                  <v-col cols="12" class="py-0">
                    <v-alert border="right" colored-border type="info" elevation="2" class="bs-small-bold-text pa-4" width="100%">
                      Users will be created with a coupon in Stripe
                      <br />
                      This will not require payment method.
                    </v-alert>
                  </v-col>

                  <v-col cols="12" class="py-0" v-if="create_response.alert">
                    <v-alert border="right" colored-border type="success" elevation="2" class="bs-small-bold-text pa-4" width="100%">
                      Details have been sent to the registered email account.
                      <br />
                      These are not saved and will not be shown again.
                      <br />
                      <br />
                      Email: {{ create_response.email }}
                      <br />
                      Password: {{ create_response.password }}
                    </v-alert>
                  </v-col>
                </v-form>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-8 pb-8">
            <div class="red--text small-font">{{ dialogStatus }}</div>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-4" text outlined @click="save()" :disabled="!valid || requestSent">
              {{ formButtonTitle }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Edit Dialog Box: end -->

      <!-- Password Box: start -->
      <v-dialog v-model="passwordDialog" max-width="400px" flat>
        <v-card>
          <v-card-title class="text-center">
            <h4 class="text-uppercase text-center grey--text my-1" style="width: 100%">Reset Password</h4>
          </v-card-title>

          <v-card-text class="pb-0 mb-0">
            <v-container>
              <v-row>
                <v-form ref="passwordform" v-model="passwordValid" lazy-validation class="d-flex justify-center" style="flex-wrap: wrap; width: 100%">
                  <v-col cols="12" sm="6" md="12" class="py-0">
                    <v-text-field v-model="passwords.password" :rules="passwordRules" label="New Password" type="password" dense solo></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12" class="py-0">
                    <v-text-field
                      v-model="passwords.repeatPassword"
                      :rules="repeatPasswordRules"
                      label="Repeat Password"
                      type="password"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-form>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="px-8 pb-8 small-font">
            <div>{{ passwordDialogStatus }}</div>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined text @click="closeChangePassword">Cancel</v-btn>
            <v-btn color="blue darken-4" text outlined @click="confirmChangePassword()" :disabled="!passwordValid || requestSent">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Password Dialog Box: end -->

      <!-- Delete Dialog Box: start -->
      <v-dialog v-model="dialogDelete" max-width="500px" width="500px">
        <v-card>
          <v-card-title class="text-center my-2 justify-center">Are you sure you want to delete this user?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined text @click="closeDelete" style="letter-spacing: normal">Cancel</v-btn>
            <v-btn color="red" outlined text @click="deleteItemConfirm()" :disabled="requestSent" style="letter-spacing: normal">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- users datatable -->
      <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="user_count"
        sort-by="id"
        class="mx-5 my-2 px-5 v-sheet v-card fill-height"
        dense
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 50, 100, 250] }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <!-- add / edit user modal -->

            <v-btn color="primary" small dark text outlined class="py-2 rounded-0 text-capitalize" @click="dialog = true">
              <v-icon small class="mr-1">mdi-account-multiple-plus-outline</v-icon>
              Add New
            </v-btn>
            <v-btn color="primary" small dark text outlined class="py-2 rounded-0 text-capitalize" @click="initialize()">
              <v-icon class="mr-0" small>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </v-toolbar>
        </template>

        <!-- User Email Verified slot for text manipulation -->
        <!-- <template v-slot:[`item.verified`]="{ item }">
          <span>{{ item.verified ? "Verified" : "Unverified" }}</span>
        </template> -->
        <template v-slot:[`item.firstName`]="{ item }">
          <span>{{ format_names(item.firstName) + " " + format_names(item.lastName) }}</span>
        </template>

        <!-- User role slot for text capitalization -->
        <template v-slot:[`item.group`]="{ item }">
          <span class="text-capitalize">{{ item.group }}</span>
        </template>

        <!-- Actions slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="primary" class="mr-2" small @click="changePassword(item)">mdi-lock-reset</v-icon>
          <v-icon color="primary" small class="mr-2" @click="editItem(item)">mdi-account-edit</v-icon>
          <!-- <v-icon color="red" small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>

        <!-- Empty table slot -->
        <template v-slot:no-data>
          <div>No Users found...</div>
        </template>
      </v-data-table>
      <!-- Data table for contct list: end -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  // props: ["users", "usersRequest"],
  name: "AdminManageUsers",
  data: () => ({
    users: [],
    options: {
      page: 1,
      itemsPerPage: 20,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    user_count: 0,

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "First Name", align: "start", sortable: true, value: "firstName" },
      // { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      // { text: "Group", value: "group" },
      { text: "Type", value: "type" },
      // { text: "Verified", value: "verified" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      email: "",
      group: "",
      type: "",
      verified: false,
      credit: null,
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      email: "",
      group: "",
      type: "",
      verified: false,
      credit: null,
    },
    password: "",
    repeatPassword: "",
    groups: ["admin", "user"],
    usertypes: [
      { text: "Guest", value: "guest" },
      { text: "Admin", value: "admin" },
    ],
    adminVerifiedOptions: ["verified", "unverified"],

    // form values
    valid: true,
    requestSent: false,
    firstNameRules: [(v) => !!v || "First Name is required"],
    lastNameRules: [(v) => !!v || "Last Name is required"],
    emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    groupRules: [(v) => !!v || "User group is required"],
    adminVerifiedRules: [(v) => !!v || "Verification status is required"],
    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 8) || "Password should be greater than 8 characters"],
    repeatPasswordRules: [(v) => !!v || "Retype Password", (v) => (v && v.length >= 8) || "Password should be greater than 8 characters"],

    // table loading
    errorStatus: "No contacts available, reload?",

    // alert
    dialogStatus: "",

    // user passwords change
    passwordDialog: false,
    passwordValid: false,
    passwordDialogStatus: "",
    passwords: {
      id: 0,
      password: "",
      repeatPassword: "",
    },

    // alerts fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    create_response: {
      alert: true,
      email: null,
      password: null,
    },
  }),

  computed: {
    loading() {
      return this.requestSent;
    },
    formTitle() {
      return this.editedIndex == -1 ? "Add User" : "Edit User";
    },
    formButtonTitle() {
      return this.editedIndex == -1 ? "Add User" : "Update User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
      if (!val) this.$refs.form.resetValidation();
      this.reset_create_response();
    },
    dialogDelete(val) {
      val || this.closeDelete();
      if (!val) this.$refs.form.resetValidation();
    },
    async options() {
      await this.getUsers();
    },
    requestSent(newval) {
      if (newval) this.setSnackbar(false, "", "");
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      await this.getUsers();
    },
    async getUsers() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.post("/api/users/getusers", { options: this.options });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.users = res.data.users;
        this.user_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", error.message ? error.message : "An error occuered while trying to get users - kindly check your internet connection");
      }
    },

    // snackbar and overlay
    setSnackbar(show, title, text) {
      this.showSnackbar = show;
      this.snackbarText = title;
      this.snackbarText2 = text;
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.verified = this.editedItem.verified ? "verified" : "unverified";
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.requestSent = true;
      this.axios
        .put("/api/users/user/delete", {
          id: this.editedItem.id,
        })
        .then((res) => {
          this.requestSent = false;
          this.setSnackbar(true, "Message", res.data.message);
          if (!res.data.success) return;
          this.closeDelete();
          this.users.splice(this.editedIndex, 1);
        })
        .catch((err) => {
          this.requestSent = false;
          this.setSnackbar(true, "Error", "User could not be deleted - check your internet connection");
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialogStatus = "";
        this.password = "";
        this.repeatPassword = "";
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      try {
        // Preparing data
        this.dialogStatus = "";
        let validated = this.$refs.form.validate();
        if (!validated) return (this.dialogStatus = "Kindly check all the fields");

        // this.requestSent = true;
        let user = Object.assign({}, this.editedItem);
        user.group = user.group.toLowerCase();
        user.verified = true;

        if (this.editedIndex > -1) {
          this.dialogStatus = "Updating user...";
          this.requestSent = true;

          const res = await this.axios.put("/api/users/user/edit", user);
          this.requestSent = false;
          if (!res.data.success) throw new Error(res.data.message);

          // update: success case
          this.setSnackbar(true, "Message", res.data.message);
          Object.assign(this.users[this.editedIndex], this.editedItem);
        } else {
          this.requestSent = true;
          const res = await this.axios.post("/api/subscriptions/admincreateguestaccount", user);
          this.requestSent = false;
          this.setSnackbar(true, "Message", res.data.message);
          if (!res.data.success) throw new Error(res.data.message);

          this.reset_form();
          this.users.push(res.data.user);
          this.$refs.form.resetValidation();

          this.create_response.alert = true;
          this.create_response.email = res.data.user.email;
          this.create_response.password = res.data.user.hash;
        }
      } catch (error) {
        console.log(error);
        this.requestSent = false;
        this.setSnackbar(true, "Error", error.message ? error.message : "User could not be deleted - check your internet connection");
      }
    },

    changePassword(item) {
      this.passwords.id = item.id;
      this.passwordDialog = true;
    },

    confirmChangePassword() {
      let validated = this.$refs.passwordform.validate();
      if (!validated) {
        this.passwordDialogStatus = "Kindly check all the fields";
        return;
      }

      if (!this.passwords.id) {
        this.passwordDialogStatus = "Kindly select a user";
        return;
      }

      if (this.passwords.password !== this.passwords.repeatPassword) {
        this.passwordDialogStatus = "Passwords do not match";
      }

      this.requestSent = true;
      this.passwordDialogStatus = "Changing password...";
      this.axios
        .put("/api/users/user/changeuserpassword", this.passwords)
        .then((res) => {
          this.requestSent = false;
          this.passwordDialogStatus = res.data.message;
          this.setSnackbar(true, "Message", res.data.message);
          if (!res.data.success) return;
          this.closeChangePassword();
        })
        .catch((err) => {
          this.requestSent = false;
          this.setSnackbar(true, "Error", "Password could not be updated - kindly check your internet connection");
          console.log(err);
        });
    },
    closeChangePassword() {
      this.passwordDialog = false;
      this.passwords.id = 0;
      this.passwords.password = "";
      this.passwords.repeatPassword = "";
      this.passwordDialogStatus = "";
    },

    reset_create_response() {
      this.create_response.alert = false;
      this.create_response.email = null;
      this.create_response.password = null;
    },

    reset_form() {
      this.editedItem = {
        firstName: "",
        lastName: "",
        email: "",
        group: "",
        type: "",
        verified: false,
        credit: null,
      };
    },
    format_names(value) {
      return value ? value.replace(/-/g, "") : "";
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

.bs-small-bold-text {
  font-size: 13px !important;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
