<template>
  <div class="progress-scroller-container d-flex flex-column justify-start align-end" v-if="show">
    <v-btn color="blue darken-4" small outlined class="py-5 text-capitalize progress-scroller-button" @click="child_show = !child_show" v-if="minimize">
      <v-icon small dark>mdi-arrow-left</v-icon>
      <!-- <v-icon v-else dark>mdi-arrow-right-box</v-icon> -->
    </v-btn>

    <v-card elevation="16" max-width="500" width="500" class="mx-auto" v-if="!minimize">
      <v-toolbar color="blue darken-4" dark dense small height="40">
        <v-toolbar-title small class="card-heading">Progress</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="child_show = !child_show" small>
          <v-icon small>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('update-upload-progress', false)" small>
          <v-icon small>mdi-window-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-virtual-scroll :items="progress_items" height="400" item-height="40">
        <template v-slot:default="{ item }">
          <v-list-item :key="item.link" dense>
            <v-tooltip bottom right close-delay="1000" open-delay="1000" color="blue darken-4" v-if="item.link || item.error">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-content v-bind="attrs" v-on="on">
                  <v-list-item-title>
                    {{ shortenLink(item.message) }}
                    <strong :class="{ 'red--text': !item.success, 'green--text': item.success }">({{ item.success ? "success" : "failed" }})</strong>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <div class="tooltip_text" v-if="item.link || item.error">
                <template v-if="item.link">
                  <div>URL: {{ item.link }}</div>
                </template>

                <template v-if="item.error && item.error.length">
                  <br />
                  <h4>Error:</h4>
                  <div>{{ item.error }}</div>
                </template>
              </div>
            </v-tooltip>

            <!-- if not  link or error: DO NOT SHOW TOOLTIP-->
            <div v-else>
              <v-list-item-content>
                <v-list-item-title>
                  {{ shortenLink(item.message) }}
                  <strong :class="{ 'red--text': !item.success, 'green--text': item.success }">({{ item.success ? "success" : "failed" }})</strong>
                </v-list-item-title>
              </v-list-item-content>
            </div>

            <v-list-item-action style="cursor: pointer" v-if="item.link">
              <a :href="item.link" target="_blank"><v-icon small>mdi-open-in-new</v-icon></a>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UploadProgressScroller",
  props: ["progress_show", "progress_items"],
  data: () => ({
    loading: false,
    child_show: false,
  }),
  computed: {
    show() {
      return this.progress_show;
    },
    minimize() {
      return !this.child_show;
    },
  },
  async mounted() {
    await this.initialize_socket_listeners();
  },
  methods: {
    shortenLink(link) {
      if (link.length > 50) return link.substring(0, 20) + "........" + link.substring(link.length - 20);
      return link;
    },
    async initialize_socket_listeners() {
      try {
        const socket = this.$store.state.socket.socket;
        if (!socket) await new Promise((r) => setTimeout(r, 500));

        socket.on("busichat_upload_started", async (data) => (this.loading = true));
        socket.on("busichat_upload_completed", (data) => (this.loading = false));
        socket.on("busichat_upload_error", (data) => (this.loading = false));
      } catch (error) {
        console.log(error);
        // this.snackbarShow(true, "Error", error.message ? error.message : "Connection could not be established");
      }
    },
  },
};
</script>

<style scoped>
.progress-scroller-container {
  position: fixed;
  bottom: 40px;
  right: 10px;
  z-index: 999;
}
.progress-scroller-button {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 999;
}

.card-heading {
  font-size: 14px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.tooltip_text {
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
