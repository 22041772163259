<template>
  <!-- Data table for contct list: start -->

  <v-row class="pa-3">
    <v-col cols="12" class="px-0">
      <v-overlay :value="loading" z-index="1000">
        <v-snackbar v-model="loading" timeout="-1" centered>
          <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
          <span class="py-2 px-2">Processing...</span>
        </v-snackbar>
      </v-overlay>

      <!-- Snackbar for small info alerts -->
      <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
        <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
        <div class="py-1" style="font-size: 13px">
          <div>{{ snackbarText2 }}</div>
        </div>
        <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
      </v-snackbar>

      <!-- Edit Dialog Box: start -->
      <v-dialog v-model="dialog" width="400">
        <v-card class="dialog-card">
          <v-card-title class="text-center">
            <h4 class="text-uppercase text-center grey--text my-0" style="width: 100%">Create Chatbot</h4>
          </v-card-title>

          <v-card-text class="pb-0 mb-0">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="chatbot.name" required label="Chatbot Name" dense solo hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mx-2 py-5">
            <div class="red--text small-font">{{ dialogStatus }}</div>
            <v-spacer></v-spacer>
            <!-- <v-btn color="grey" outlined text @click="close">Cancel</v-btn> -->

            <v-btn color="warning" small @click="close" outlined>
              <v-icon right dark class="mr-1 ml-1">mdi-close-circle-outline</v-icon>
              Cancel
            </v-btn>

            <v-btn color="success" small @click="create_chatbot" outlined>
              <v-icon right dark class="mr-1 ml-1">mdi-content-save</v-icon>
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Edit Dialog Box: end -->

      <!-- Delete Dialog Box: start -->
      <v-dialog v-model="dialogDelete" width="400px">
        <v-card class="dialog-card">
          <v-card-title class="text-center my-2 justify-center delete-dialog" style="word-break: break-word">
            Are you sure you want to delete chatbot "{{ delete_dialog.chatbot_name }}" & all its data?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" outlined text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red" outlined text @click="deleteItemConfirm()" :disabled="requestSent">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- users datatable -->
      <v-data-table
        :headers="headers"
        :items="chatbots"
        :options.sync="options"
        :server-items-length="chatbot_count"
        @click:row="visitChatbot"
        sort-by="id"
        class="px-3 v-sheet v-card fill-height"
        dense
        :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, 200] }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <!-- add / edit user modal -->

            <v-btn small color="primary" dark text outlined class="py-5 rounded-0 text-capitalize d-flex justify-center align-center" @click="dialog = true">
              <v-icon small class="mr-1">mdi-chat-plus-outline</v-icon>
              <span>Add New</span>
            </v-btn>
            <v-btn small color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="initialize()">
              <v-icon small>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </v-toolbar>
        </template>

        <!-- Actions slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left dense small class="action-menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense flat>
              <v-list-item class="action-list-item" @click="visitChatbot(item)">
                <v-list-item-title class="action-list-item-title">
                  <v-icon color="primary" small class="mr-2">mdi-comment-edit-outline</v-icon>
                  Edit Chatbot
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="action-list-item" @click="go_to_sessions(item)">
                <v-list-item-title class="action-list-item-title">
                  <v-icon color="primary" small class="mr-2">mdi-forum</v-icon>
                  Check Sessions
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="action-list-item" @click="deleteItem(item)">
                <v-list-item-title class="action-list-item-title">
                  <v-icon color="red" small class="mr-2">mdi-delete</v-icon>
                  Delete Chatbot
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- Empty table slot -->
        <template v-slot:no-data>
          <div>{{ loading ? "Loading chatbots" : "No chatbots found." }}</div>
        </template>

        <!-- <template v-slot:[`footer.prepend`]>
          <div>Total Sessions: {{ total_sessions }}</div>
        </template> -->
      </v-data-table>
      <!-- Data table for contct list: end -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  // props: ["users", "usersRequest"],
  data: () => ({
    chatbots: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Name", align: "start", sortable: true, value: "name" },
      { text: "Files / URLs", align: "start", sortable: false, value: "files_count" },
      { text: "Sessions", align: "start", sortable: false, value: "sessions_count" },
      // { text: "Chunks", align: "start", sortable: false, value: "chunks_count" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    options: {
      page: 1,
      itemsPerPage: 10,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    chatbot_count: 0,
    chatbot: {
      name: "",
    },
    delete_dialog: {
      chatbot_id: null,
      chatbot_index: -1,
      chatbot_name: null,
    },

    editedIndex: -1,
    editedItem: {
      firstName: "",
    },
    defaultItem: {
      firstName: "",
    },

    // form values
    valid: true,
    requestSent: false,
    NameRules: [(v) => !!v || "Name is required"],

    // table loading
    errorStatus: "No contacts available, reload?",

    // alert
    dialogStatus: "",

    // alerts fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",
  }),

  computed: {
    loading() {
      return this.requestSent;
    },
    formTitle() {
      return this.editedIndex == -1 ? "Add User" : "Edit User";
    },
    formButtonTitle() {
      return this.editedIndex == -1 ? "Add User" : "Update User";
    },
    total_sessions() {
      let t = 0;
      for (const cb of this.chatbots) t += parseInt(cb.sessions_count) || 0;
      return t;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options() {
      this.getChatbots();
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      await this.getChatbots();
    },
    async getChatbots() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.post("/api/openai/getuserchatbots", { options: this.options });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error("Chatbots could not be loaded");
        this.chatbots = res.data.chatbots;
        this.chatbot_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "An error occuered while trying to get chatbots - kindly check your internet connection");
      }
    },

    async create_chatbot() {
      try {
        if (this.requestSent) return;
        if (!this.chatbot.name) throw new Error("Chatbot name can not be empty");

        this.requestSent = true;
        let res = await this.axios.post("/api/openai/createchatbot", {
          chatbot: { name: this.chatbot.name },
        });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.chatbots.push(res.data.chatbot);
        this.close();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "An error occuered while trying to create chatbot - kindly check your internet connection");
      }
    },
    async delete_chatbot() {},

    // snackbar and overlay
    setSnackbar(show, title, text) {
      this.showSnackbar = show;
      this.snackbarText = title;
      this.snackbarText2 = text;
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.verified = this.editedItem.verified ? "verified" : "unverified";
      this.dialog = true;
    },

    deleteItem(item) {
      this.delete_dialog.chatbot_id = item.id;
      this.delete_dialog.chatbot_name = item.name;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        if (!this.delete_dialog.chatbot_id || this.requestSent) return;
        this.requestSent = true;
        const id = this.delete_dialog.chatbot_id;

        let res = await this.axios.post("/api/openai/deletechatbot", { id });

        this.requestSent = false;
        this.setSnackbar(true, "Message", res.data.message);
        if (!res.data.success) return;

        const index = this.chatbots.findIndex((cb) => cb.id == id);
        this.chatbots.splice(index, 1);
        this.closeDelete();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(
          true,
          "Error",
          error.message ? error.message : "An error occuered while trying to delete chatbots - kindly check your internet connection"
        );
      }
    },

    close() {
      this.dialog = false;
      this.chatbot.name = "";
    },

    closeDelete() {
      this.delete_dialog.chatbot_id = null;
      this.delete_dialog.chatbot_name = null;
      this.dialogDelete = false;
    },

    save() {
      // Preparing data
      this.dialogStatus = "";
      let validated = this.$refs.form.validate();
      if (!validated) return (this.dialogStatus = "Kindly check all the fields");

      // this.requestSent = true;
      let user = Object.assign({}, this.editedItem);
      user.group = user.group.toLowerCase();
      user.verified = user.verified.toLowerCase() == "verified" ? true : false;

      if (this.editedIndex > -1) {
        this.dialogStatus = "Updating user...";

        this.axios
          .put("/api/users/user/edit", user)
          .then((res) => {
            this.requestSent = false;
            this.dialogStatus = res.data.message;
            this.setSnackbar(true, "Message", res.data.message);
            if (!res.data.success) return;
            Object.assign(this.users[this.editedIndex], this.editedItem);
          })
          .catch((err) => {
            this.requestSent = false;
            this.setSnackbar(true, "Error", "User could not be updated - Check your internet connection");
          });
      } else {
        // creating new user
        if (this.password !== this.repeatPassword) {
          this.dialogStatus = "Passwords do not match";
          this.requestSent = false;
          return;
        }

        user.password = this.password;
        user.repeatPassword = this.repeatPassword;

        this.dialogStatus = "Adding user...";
        this.axios
          .post("/api/users/adminregister", user)
          .then((res) => {
            this.requestSent = false;
            this.setSnackbar(true, "Message", res.data.message);
            if (!res.data.success) {
              this.dialogStatus = res.data.message;
              return;
            }

            this.close();
            this.users.push(res.data.newUser);
            this.$refs.form.resetValidation();
          })
          .catch((err) => {
            console.log(err);
            this.requestSent = false;
            this.setSnackbar(true, "Error", "User could not be added - Check your internet connection");
          });
      }
    },

    visitChatbot(chatbot) {
      console.log(chatbot);
      this.$router.push({ name: "Chatbot2", params: { chatbot_id: chatbot.id } });
    },

    go_to_sessions(chatbot) {
      try {
        this.$router.push({ name: "Sessions", params: chatbot });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.action-list-item {
  font-size: 13px !important;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 16px;
}

.action-list-item-title {
  font-size: 13px !important;
  cursor: pointer;
}

::v-deep .v-data-table tr {
  cursor: pointer;
}

.delete-dialog {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgb(71, 71, 71);
}
</style>
