<template>
  <v-sheet color="white" elevation="3" rounded class="my-0 mx-0 w-100">
    <text-view-dialog :show="show_read_dialog" :transcripts="dialog_transcripts" :text="dialog_text" @close-read-dialog="(v) => (show_read_dialog = v)"></text-view-dialog>

    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="5000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <template>
      <v-data-table
        v-model="selected"
        :headers="computed_headers"
        :items="items"
        :options.sync="options"
        :server-items-length="items_length"
        item-key="id"
        class="rounded-6 datatable-height"
        :show-select="show_select"
        :single-select="singleSelect"
        :show-expand="false"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        fixed-header
        dense
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 500] }"
        :loading="table_loading"
        elevation="10"
      >
        <template v-slot:top>
          <!-- History row -->
          <v-row class="px-4 align-baseline justify-center btns-div" no-gutters>
            <v-col cols="12" md="12" class="mx-0 px-0mr-0 py-0">
              <h4 class="my-2 mx-0 px-0 text-left w-100 d-flex flex-row align-baseline justify-space-between">
                <span></span>

                <div>
                  <call-filter-dialog v-if="!hide_filters" @filters-updated="(v) => (filters = v)"></call-filter-dialog>
                  <v-btn color="white" class="small-font ml-1 my-1" style="text-transform: capitalize" @click="load_items()" small elevation="2">
                    <v-icon class="mr-1" small>mdi-refresh</v-icon>
                    Refresh
                  </v-btn>

                  <v-btn v-if="show_select" color="white" class="small-font ml-1 my-1" style="text-transform: capitalize" @click="selected = []" small elevation="2">
                    <v-icon class="mr-1" small>mdi-select-off</v-icon>
                    Deselect All {{ selected.length ? `(${selected.length})` : "" }}
                  </v-btn>
                </div>
              </h4>
            </v-col>
          </v-row>
        </template>

        <!-- Date slot -->
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ format_date(item.createdAt) }}
        </template>

        <template v-slot:[`item.res.call_length`]="{ item }">
          {{ item.res && item.res.call_length ? item.res.call_length.toFixed(3) : "" }}
        </template>

        <!-- Actions slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="btn-div">
            <!-- delete  history -->
            <v-tooltip color="bssecondary2" left :open-delay="50">
              <template v-slot:activator="{ on }">
                <v-icon color="#ff6e63" small class="mr-0" @click="remove_single_item(item)" v-on="on">mdi-delete</v-icon>
              </template>
              <span>Remove call from history</span>
            </v-tooltip>

            <!-- recording link -->
            <v-tooltip v-if="item.res && item.res.recording_url" color="bssecondary2" left :open-delay="50">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" small class="mr-0" @click="visit_link(item.res.recording_url)" v-on="on">mdi-play-circle-outline</v-icon>
              </template>
              <span>Visit Recording Link</span>
            </v-tooltip>

            <!-- recording link -->
            <v-tooltip v-if="item.res && item.res.transcripts.length" color="bssecondary2" left :open-delay="50">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" small class="mr-0" @click="show_transcript(item)" v-on="on">mdi-file-outline</v-icon>
              </template>
              <span>Show Transcript</span>
            </v-tooltip>

            <!-- Load history -->
            <v-tooltip v-if="item.req && show_load_settings" color="bssecondary2" left :open-delay="50">
              <template v-slot:activator="{ on }">
                <v-icon color="primary" small class="mr-2" @click="load_single_item(item)" v-on="on">mdi-arrow-up-bold-circle-outline</v-icon>
              </template>
              <span>Load call settings</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </template>
  </v-sheet>
</template>

<script>
import TextViewDialog from "./TextViewDialog.vue";
import CallFilterDialog from "./CallFilterDialog.vue";
export default {
  name: "CampaignTable",
  props: ["hide_filters", "show_load_settings", "fixed_filters", "exclude_columns", "items_per_page"],
  components: {
    TextViewDialog,
    CallFilterDialog,
  },
  data() {
    return {
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      // table fields
      items: [],
      items_length: 0,
      headers: [
        { text: "Created", value: "createdAt", align: "start", sortable: true },
        { text: "Name", value: "name", align: "start", sortable: true },
        { text: "To", value: "phone_number", align: "start", sortable: true },
        { text: "From", value: "res.from", align: "start", sortable: true },
        { text: "Type", value: "type", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Length", value: "res.call_length", sortable: true },
        { text: "Cost", value: "res.price", sortable: true },
        // { text: "Campaign", value: "campaign.name", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      options: {
        page: 1,
        itemsPerPage: 50,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      expanded: [],
      singleExpand: false,
      show_select: false,
      singleSelect: false,
      selected: [],
      table_loading: false,
      requestSent: false,
      filters: {},

      show_read_dialog: false,
      dialog_text: "",
      dialog_transcripts: [],
    };
  },
  computed: {
    computed_headers() {
      let headers = this.headers;
      if (this.exclude_columns && this.exclude_columns.length) headers = this.headers.filter((h) => !this.exclude_columns.includes(h.text.toLowerCase()));
      return headers;
    },
  },
  watch: {
    options() {
      this.load_items();
    },
    filters: {
      handler() {
        this.load_items();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.items_per_page ? (this.options.itemsPerPage = this.items_per_page) : await this.load_items();
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while initializing page");
      }
    },

    async load_items() {
      try {
        // checking for other requests
        if (this.table_loading) return;
        this.table_loading = true;

        const filters = _.cloneDeep(this.filters);
        if (this.fixed_filters) for (const [k, v] of Object.entries(this.fixed_filters)) if (v) filters[k] = v;

        let res = await this.axios.post("/api/voicecalling/loadcalls", { options: this.options, filters });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.items = res.data.calls;
        this.items_length = res.data.count;
      } catch (error) {
        this.table_loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", "campaigns could not be loaded: check your internet connection");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    async load_single_item(h) {
      try {
        this.$emit("load-call-settings", h);
      } catch (error) {
        console.log(error);
      }
    },

    async remove_single_item(item) {
      try {
        this.table_loading = true;
        const id = item.id;
        let res = await this.axios.post("/api/voicecalling/removecall", { id });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        await this.load_items();
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", error.message ? error.message : "Call could not be removed: check your internet connection");
      }
    },

    format_date(date) {
      if (!date) return date;
      return date.replace("T", " ").replace(/\.[^.]*$/gi, "");
    },

    visit_link(l) {
      window.open(l, "_blank");
    },

    show_transcript(item) {
      this.dialog_text = item.res.concatenated_transcript;
      this.dialog_transcripts = item.res.transcripts;
      this.show_read_dialog = true;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

::v-deep .btn-div * {
  font-weight: 500;
  font-size: 15px !important;
}
</style>
