<template>
  <v-row class="justify-center ma-1">
    <v-overlay :value="loading" z-index="1000">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-1 pa-1">
      <v-card class="px-0 py-5 ma-0 d-flex flex-column justify-space-between align-center">
        <v-row class="w-100 pa-0 ma-0">
          <v-col cols="12" sm="12" md="12" class="d-flex justify-end align-end">
            <v-btn small color="primary" dark text outlined class="py-2 rounded-0 text-capitalize" @click="initialize()">
              <v-icon small>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </v-col>
        </v-row>

        <!-- Message Display-->
        <div class="messages-section w-100 d-flex flex-column justify-start align-center">
          <v-card class="w-50" flat>
            <!-- <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row class="subscription-form-buttons">
                  <template>
                    <!-- USER PLAN UPDATE -->
                    <template>
                      <v-col cols="12" sm="12" class="pb-0">
                        <h4 class="pb-0">Update Subscription Plan</h4>
                      </v-col>
                      <v-col cols="12" class="py-1">
                        <!-- selecting product -->
                        <v-select
                          hide-details
                          label="Select Product"
                          v-model="item.price_id"
                          :items="recurring_plans"
                          :item-text="(v) => `${v.product_name} (Price: $${v.price_amount}, Sessions: ${v.session_limit})`"
                          item-value="price_id"
                          solo
                          dense
                          class="text-sm dialog-textfield my-2"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                        <v-alert border="right" colored-border type="info" elevation="2" class="text-sm dialog-textfield my-1" width="100%">
                          This will use default payment method attached to the user, and can be changed from settings below.
                        </v-alert>
                      </v-col>
                      <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                        <v-btn color="primary" small @click="update_subscription" prepend-icon="mdi-link-plus" outlined>Update Subscription</v-btn>
                      </v-col>
                    </template>

                    <!-- USER BUY SESSIONS -->
                    <template>
                      <v-col cols="12" sm="12" class="pb-0">
                        <h4 class="pt-4 pb-0">Buy</h4>
                      </v-col>
                      <template v-if="user && user.subscription.plan.other_allowed_features.additional_sessions">
                        <!-- selecting user -->
                        <v-col cols="12" class="py-1">
                          <!-- selecting product -->
                          <v-select
                            hide-details
                            label="Select Product"
                            v-model="buy_item.price_id"
                            :items="one_time_plans"
                            :item-text="(v) => `${v.product_name} (Price: $${v.price_amount}, Sessions: ${v.session_limit})`"
                            item-value="price_id"
                            solo
                            dense
                            class="text-sm dialog-textfield my-2"
                          ></v-select>

                          <!-- selecting quantity -->
                          <v-text-field
                            type="number"
                            hide-details
                            label="Quantity"
                            v-model="buy_item.quantity"
                            solo
                            dense
                            class="text-sm dialog-textfield my-3"
                          ></v-text-field>
                          <v-text-field
                            hide-details
                            label="Quantity"
                            :value="total_price"
                            solo
                            dense
                            class="text-sm dialog-textfield my-1"
                            disabled
                            style="color: black !important"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                          <v-alert border="right" colored-border type="info" elevation="2" class="text-sm dialog-textfield my-1 mb-2" width="100%">
                            This will use default payment method attached to the user, and can be changed from settings below.
                          </v-alert>
                        </v-col>
                        <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                          <v-btn color="primary" small @click="buy_sessions" prepend-icon="mdi-link-plus" outlined>
                            <v-icon right dark class="mr-1 ml-0">mdi-cart-check</v-icon>
                            Buy
                          </v-btn>
                        </v-col>
                      </template>
                      <!-- case when user is nto allowed to buy further sessions -->
                      <template v-else>
                        <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                          <v-alert border="right" colored-border type="info" elevation="2" class="text-sm dialog-textfield my-1 mb-2" width="100%">
                            Upgrade your subscription plan to access additional sessions.
                            <br />
                            Current plan does not allow their in-app purchases.
                          </v-alert>
                        </v-col>
                      </template>
                    </template>

                    <!-- USER Add payment method section -->
                    <template>
                      <v-col cols="12" sm="12" class="pb-0">
                        <h4 class="pt-4 pb-0">Add Payment Method</h4>
                        <div ref="cardElement" class="text-sm dialog-textfield my-3 stripe-div"></div>
                      </v-col>
                      <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                        <v-btn color="primary" small @click="add_payment_method" prepend-icon="mdi-link-plus" outlined class="mx-1">Add Payment Method</v-btn>
                      </v-col>
                    </template>

                    <!-- USER Update payment method -->
                    <template>
                      <v-col cols="12" sm="12" class="pb-0">
                        <h4 class="pb-0 pt-4">Update Payment Methods</h4>
                      </v-col>
                      <v-col cols="12" class="py-1">
                        <!-- selecting product -->
                        <v-radio-group
                          v-model="selected_payment_method"
                          column
                          class="text-sm dialog-textfield my-3"
                          hide-details
                          v-if="payment_methods.length"
                        >
                          <v-radio
                            v-for="pm of payment_methods"
                            :key="pm.id"
                            :label="`${pm.card.brand} **** **** **** ${pm.card.last4}`"
                            color="warning"
                            :value="pm.id"
                          ></v-radio>
                        </v-radio-group>
                        <div v-else class="text-sm dialog-textfield my-2">No payment methods found for the user</div>
                      </v-col>
                      <v-col cols="12" class="py-1 d-flex flex-row flex-wrap align-rigth justify-end" wrap>
                        <v-btn color="primary" small @click="set_default_payment_method" prepend-icon="mdi-link-plus" outlined class="my-1">
                          Set As Default
                        </v-btn>
                        <v-btn color="red" small @click="delete_payment_method" prepend-icon="mdi-link-plus" outlined class="my-1 ml-2">Delete Method</v-btn>
                      </v-col>
                    </template>

                    <!-- USER DELETE -->
                    <template>
                      <v-col cols="12" sm="12" class="pb-0 pt-8">
                        <h4 class="pb-3 red--text">Deactivate Subscription</h4>
                      </v-col>

                      <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end" v-if="user">
                        <v-btn color="warning" small @click="toggle_activation_user" prepend-icon="mdi-link-plus" outlined class="py-1">
                          <v-icon right dark class="mr-1 ml-0">mdi-power</v-icon>
                          {{ user.subscription.active ? "Deactivate " : "Activate " }} Subscription
                        </v-btn>
                      </v-col>
                    </template>
                  </template>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const config = require("../../../../config/app.json");
export default {
  name: "UserSubUpdate",

  data: () => ({
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    busy: false,
    item: {
      price_id: null,
    },
    products: [],

    user_id: null,
    users: [],
    selected_user: null,

    payment_methods: [],
    selected_payment_method: null,

    stripeCardElement: null,
    stripe: null,

    buy_item: {
      user_id: null,
      price_id: null,
      payment_method: null,
      quantity: 1,
    },

    user: null,
  }),
  computed: {
    loading() {
      return this.busy;
    },
    total_price() {
      if (!this.products.length || !this.buy_item.price_id) return 0;
      const index = this.products.findIndex((p) => p.price_id == this.buy_item.price_id);
      return `Price: $${this.products[index].price_amount * this.buy_item.quantity}, Sessions: ${this.products[index].session_limit * this.buy_item.quantity}`;
    },
    selected_user_plan() {
      if (!this.users.length || !this.item.user_id) return "";
      const index = this.users.findIndex((u) => u.id == this.item.user_id);
      return `Current Plan: ${this.users[index].subscription.plan.product_name}`;
    },
    recurring_plans() {
      return this.products.filter((p) => p.price_type == "recurring");
    },
    one_time_plans() {
      return this.products.filter((p) => p.price_type == "one_time");
    },
  },
  watch: {},

  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_user();
        await this.get_products();
        await this.initialize_stripe();
        await this.get_payment_methods();
      } catch (error) {
        console.log(error);
      }
    },
    async initialize_stripe() {
      try {
        // Set up Stripe & stripe Elements
        this.stripe = window.Stripe(config.STRIPE_PUBLISHABLE_KEY);
        const elements = this.stripe.elements();
        this.stripeCardElement = elements.create("card");
        this.stripeCardElement.mount(this.$refs.cardElement);
      } catch (error) {
        this.snackbarShow(true, "Error", "Could not fetch payment method addition features");
      }
    },
    async get_user() {
      try {
        this.busy = true;

        let res = await this.axios.get("/api/subscriptions/getuser");
        this.busy = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.user = res.data.user;
        this.item.price_id = this.user ? this.user.subscription.price_id : null;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Users could not be fetched");
      }
    },

    async get_products() {
      try {
        // this.busy = true;

        let res = await this.axios.post("/api/subscriptions/admingetprices", { type: null });
        // this.busy = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.products = res.data.products;
      } catch (error) {
        // this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Plans could not be fetched");
      }
    },

    async get_payment_methods() {
      try {
        this.busy = true;
        let res = await this.axios.get("/api/subscriptions/paymentmethods");
        this.busy = false;
        console.log(res.data.payment_methods);
        if (!res.data.success) throw new Error(res.data.message);
        this.payment_methods = res.data.payment_methods;
        for (const pm of this.payment_methods) if (pm.default) this.selected_payment_method = pm.id;
        console.log(this.payment_methods);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Plans could not be fetched");
      }
    },

    async add_payment_method() {
      try {
        this.busy = true;
        if (!this.stripeCardElement) throw new Error("Invalid card number - Kindly fill card details to add");

        // Create a payment method with the card details
        const { paymentMethod, error } = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.stripeCardElement,
        });
        if (error) throw new Error(error.message ? error.message : error);

        const payment_method_id = paymentMethod.id;
        console.log({ payment_method_id });

        const res = await this.axios.post("/api/subscriptions/addpaymentmethod", { payment_method_id });
        if (!res.data.success) throw new Error(res.data.message);
        await this.get_payment_methods();

        this.busy = false;
        this.snackbarShow(true, "Info", res.data.message);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Payment methods could not be updated.");
      }
    },

    async set_default_payment_method() {
      try {
        this.busy = true;
        if (!this.selected_payment_method) throw new Error("Invalid payment method, select a payment method to set default");
        const payment_method_id = this.selected_payment_method;

        const res = await this.axios.post("/api/subscriptions/setdefaultpaymentmethod", { payment_method_id });
        if (!res.data.success) throw new Error(res.data.message);
        await this.get_payment_methods();

        this.busy = false;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Payment method could not be updated");
      }
    },

    async delete_payment_method() {
      try {
        this.busy = true;

        if (!this.selected_payment_method) throw new Error("Invalid payment method, select a payment method to delete");
        if (!this.payment_methods || this.payment_methods.length < 2) throw new Error("Deletion error: Atleast 1 payment method is required for subscription");

        const payment_method_id = this.selected_payment_method;
        const res = await this.axios.post("/api/subscriptions/deletepaymentmethod", { payment_method_id });
        if (!res.data.success) throw new Error(res.data.message);
        await this.get_payment_methods();
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Payment method could not be updated");
      }
    },

    async update_subscription() {
      try {
        if (this.busy) return;
        if (!this.item.price_id) throw new Error("Validation Error: Select a subscription plan to update");
        if (this.item.price_id == this.user.subscription.price_id) throw new Error("Validation Error: Can not update to the same plan");
        this.busy = true;

        // validating values
        const res = await this.axios.post("/api/subscriptions/updatesubscription", { ...this.item });
        this.busy = false;
        console.log(res.data);

        if (!res.data.success) throw new Error(res.data.message);
        this.initialize();
        this.snackbarShow(true, "Info", res.data.message);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Subscription could not be updated");
      }
    },

    async buy_sessions() {
      try {
        if (this.busy) return;
        if (!this.buy_item.price_id || !this.buy_item.quantity) throw new Error("Validation Error: Select a product and quantity to update");
        this.busy = true;

        // validating values
        const item = this.buy_item;

        const res = await this.axios.post("/api/subscriptions/buysessions", { ...this.buy_item });
        this.busy = false;
        console.log(res.data);

        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", "Sessions Updated Successfully");
      } catch (error) {
        this.busy = false;
        console.log(error.message ? error.message : error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Sessions could not be updated");
      }
    },

    async toggle_activation_user() {
      try {
        this.busy = true;

        const res = await this.axios.post("/api/subscriptions/toggleactivation", {});
        this.busy = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", "User updated successfully");
        this.user.subscription.active = !this.user.subscription.active; //toggling manually
      } catch (error) {
        this.busy = false;
        console.log("Error: ", error.message ? error.message : error);
        this.snackbarShow(true, "Error", error.message ? error.message : "User could not be updated");
      }
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    format_key(key) {
      if (key) return key.replace(/_/gm, " ").trim() + ": ";
      return key;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
.messages-section {
  height: 90%;
}

@media (max-width: 768px) {
  .w-50 {
    width: 100% !important;
  }
}

.messages-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.message {
  border-bottom: 1px solid #e4e3e3;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.messags_text {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.setting-icons {
  cursor: pointer;
}

/* scroll styling */
#message-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.user_bg {
  background-color: #f8f8f8;
}
::v-deep .message_input input {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
}
.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

::v-deep .dialog-select .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-select .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}
::v-deep .dialog-file-input .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-file-input .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-textfield input,
::v-deep .dialog-textfield .v-label {
  font-size: 13px;

  /* Add more CSS styles as needed */
}

::v-deep .script-textarea {
  font-size: 12px;
  font-weight: 500;
  color: red !important;
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: #4a4a4a !important;
  font-weight: 700;
}

.field_icons {
  cursor: pointer;
}
.color_pickers {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

::v-deep .v-input--is-disabled input {
  color: darkgray;
  cursor: not-allowed;
}
input:disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}

.fields-info-tooltip-text {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.fields-info-tooltip-icon {
  /* color: #361f00; */
}
.stripe-div {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 4px;
}

::v-deep .subscription-form-buttons .v-btn {
  color: red;
  min-width: 225px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
</style>
