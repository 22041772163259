<template>
  <v-row class="justify-center ma-1">
    <v-overlay :value="loading" z-index="1000">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-1 pa-1">
      <v-card class="px-0 py-5 ma-0 d-flex flex-column justify-space-between align-center">
        <v-row class="w-100 pa-0 ma-0">
          <v-col cols="12" sm="12" md="12" class="d-flex justify-end align-end">
            <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" :to="{ name: 'AdminSubMain' }">
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
            <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="initialize()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Message Display-->
        <div class="messages-section w-100 d-flex flex-column justify-start align-center">
          <v-card class="w-50" flat>
            <!-- <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <template>
                    <!-- chatbot settings section -->
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3">New Subscription</h4>
                    </v-col>

                    <!-- existing chatbot -->
                    <v-col cols="12" class="py-1">
                      <!-- select plan -->
                      <!-- input name -->
                      <!-- input email -->
                      <!-- input card (number, expiry, cvc, postcode) -->
                      <v-select
                        hide-details
                        label="Select Subscription Plan"
                        v-model="subscription.price_id"
                        :items="products"
                        :item-text="(v) => `${v.product_name} ($${v.price_amount} - ${v.price_type})`"
                        item-value="price_id"
                        solo
                        dense
                        class="text-sm dialog-textfield my-3"
                      ></v-select>
                      <v-text-field hide-details label="Email" v-model="subscription.name" solo dense class="text-sm dialog-textfield my-3"></v-text-field>
                      <v-text-field hide-details label="Plan" v-model="subscription.email" solo dense class="text-sm dialog-textfield my-3"></v-text-field>
                      <div ref="cardElement" class="text-sm dialog-textfield my-3 stripe-div"></div>
                    </v-col>
                    <v-col cols="12" class="py-1 d-flex flex-row align-center justify-end">
                      <v-btn color="primary" small @click="submitForm" prepend-icon="mdi-link-plus" outlined width="120px">
                        <v-icon right dark class="mr-1 ml-0">mdi-content-save</v-icon>
                        Create
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const config = require("../../../../config/app.json");
// import Stripe from "stripe"
// let stripe = Stripe(config.STRIPE_PUBLISHABLE_KEY);
// let elements = stripe.elements();
// let card = undefined;
// console.log(elements);

export default {
  name: "AdminSubCreate",

  data: () => ({
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    busy: false,

    subscription: {
      payment_method_id: null,
      name: "hamza",
      email: "hamza@gmail.com",
      price_id: "",
    },
    stripeCardElement: null,
    stripe: null,
    products: [],
  }),
  computed: {
    loading() {
      return this.busy;
    },
  },
  watch: {},
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_products();

        // Set up Stripe & stripe Elements
        this.stripe = window.Stripe(config.STRIPE_PUBLISHABLE_KEY);
        const elements = this.stripe.elements();
        this.stripeCardElement = elements.create("card");
        this.stripeCardElement.mount(this.$refs.cardElement);
      } catch (error) {
        console.log(error);
      }
    },

    async get_products() {
      try {
        this.busy = true;

        let res = await this.axios.post("/api/subscriptions/admingetprices", { type: "recurring" });
        this.busy = false;
        console.log(res.data.products);
        if (!res.data.success) throw new Error(res.data.message);
        this.products = res.data.products;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Plans could not be fetched");
      }
    },

    async submitForm() {
      try {
        if (this.busy) return;
        this.busy = true;

        // validating values
        const name = this.subscription.name.trim();
        const email = this.subscription.email.trim();
        const price_id = this.subscription.price_id.trim();

        // Create a payment method with the card details
        const { paymentMethod, error } = await this.stripe.createPaymentMethod({
          type: "card",
          card: this.stripeCardElement,
        });
        if (error) throw new Error(error.message ? error.message : error);

        const payment_method_id = paymentMethod.id;
        const body = { name, email, price_id, payment_method_id };
        console.log(body);
        const res = await this.axios.post("/api/subscriptions/admincreate", body);
        this.busy = false;
        console.log(res.data);

        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", "Subscription created successfully");
      } catch (error) {
        this.busy = false;
        console.log("Error: ", error.message ? error.message : error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Subscription could not be initiated");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    format_key(key) {
      if (key) return key.replace(/_/gm, " ").trim() + ": ";
      return key;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
.messages-section {
  height: 90%;
}

.messages-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.message {
  border-bottom: 1px solid #e4e3e3;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.messags_text {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.setting-icons {
  cursor: pointer;
}

/* scroll styling */
#message-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.user_bg {
  background-color: #f8f8f8;
}
::v-deep .message_input input {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
}
.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

::v-deep .dialog-select .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-select .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}
::v-deep .dialog-file-input .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-file-input .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-textfield input,
::v-deep .dialog-textfield .v-label {
  font-size: 13px;

  /* Add more CSS styles as needed */
}

::v-deep .script-textarea {
  font-size: 12px;
  font-weight: 500;
  color: red !important;
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: #4a4a4a !important;
  font-weight: 700;
}

.field_icons {
  cursor: pointer;
}
.color_pickers {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

::v-deep .v-input--is-disabled input {
  color: darkgray;
  cursor: not-allowed;
}
input:disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}

.fields-info-tooltip-text {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.fields-info-tooltip-icon {
  /* color: #361f00; */
}
.stripe-div {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 4px;
}
</style>
