<template>
  <v-row class="justify-center ma-1">
    <v-overlay :value="loading" z-index="1000">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-1 pa-1">
      <v-card class="px-0 py-5 ma-0 d-flex flex-column justify-space-between align-center">
        <v-row class="w-100 pa-0 ma-0">
          <v-col cols="12" sm="12" md="12" class="d-flex justify-end align-end">
            <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="initialize()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Message Display-->
        <div class="messages-section w-100 d-flex flex-column justify-start align-center">
          <v-card class="w-50" flat>
            <!-- <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <template>
                    <!-- chatbot settings section -->
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3">Buy Sessions</h4>
                    </v-col>

                    <!-- selecting user -->
                    <v-col cols="12" class="py-1">
                      <v-select
                        hide-details
                        label="Select User"
                        v-model="item.user_id"
                        :items="users"
                        :item-text="(v) => `${v.firstName} (${v.email})`"
                        item-value="id"
                        solo
                        dense
                        class="text-sm dialog-textfield my-3"
                      ></v-select>

                      <!-- showing urrent plan in a disabled field -->
                      <v-text-field
                        hide-details
                        label="Current Plan"
                        :value="selected_user_plan"
                        solo
                        dense
                        class="text-sm dialog-textfield my-3"
                        disabled
                        style="color: black !important"
                      ></v-text-field>

                      <!-- selecting product -->
                      <v-select
                        hide-details
                        label="Select Product"
                        v-model="item.price_id"
                        :items="products"
                        :item-text="
                          (v) => `${v.product_name} (Price: $${v.price_amount}, Type: ${v.price_type.replace('_', ' ')}, Sessions: ${v.session_limit})`
                        "
                        item-value="price_id"
                        solo
                        dense
                        class="text-sm dialog-textfield my-3"
                      ></v-select>

                      <!-- selecting quantity -->
                      <v-text-field
                        type="number"
                        hide-details
                        label="Quantity"
                        v-model="item.quantity"
                        solo
                        dense
                        class="text-sm dialog-textfield my-3"
                      ></v-text-field>
                      <v-text-field
                        hide-details
                        label="Quantity"
                        :value="total_price"
                        solo
                        dense
                        class="text-sm dialog-textfield my-3"
                        disabled
                        style="color: black !important"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                      <v-btn color="primary" small @click="buy_sessions" prepend-icon="mdi-link-plus" outlined>
                        <v-icon right dark class="mr-1 ml-0">mdi-cart-check</v-icon>
                        Buy Sessions
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="py-1 d-flex flex-row align-rigth justify-end">
                      <v-alert border="right" colored-border type="info" elevation="2" class="text-sm dialog-textfield my-8" width="100%">
                        This will use default payment method attached to the user.
                        <br />
                        Default payment method can be changed from subscription's update page
                      </v-alert>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-col>

    <!-- Delete Dialog Box: start -->
    <v-dialog v-model="dialog_delete" width="500px" v-if="selected_user">
      <v-card class="dialog-card">
        <v-card-title class="text-left my-2 justify-center">
          Are you sure you want to delete subscription for user "{{ selected_user.firstName }} ({{ selected_user.email }})" & all its data?
          <br />
          This will delete User, Subscription, Chatbots & All Data
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" outlined text @click="dialog_delete = false">Cancel</v-btn>
          <v-btn color="red" outlined text @click="delete_user" :disabled="busy">Delete Subscription</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AdminBuySessions",

  data: () => ({
    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    busy: false,
    item: {
      user_id: null,
      price_id: null,
      payment_method: null,
      quantity: 1,
    },
    products: [],

    user_id: null,
    users: [],
    selected_user: null,
    dialog_delete: false,
  }),
  computed: {
    loading() {
      return this.busy;
    },
    total_price() {
      if (!this.products.length || !this.item.price_id) return 0;
      const index = this.products.findIndex((p) => p.price_id == this.item.price_id);
      return `Price: $${this.products[index].price_amount * this.item.quantity}, Sessions: ${this.products[index].session_limit * this.item.quantity}`;
    },
    selected_user_plan() {
      if (!this.users.length || !this.item.user_id) return "";
      const index = this.users.findIndex((u) => u.id == this.item.user_id);
      return `Current Plan: ${this.users[index].subscription.plan.product_name}`;
    },
  },
  watch: {
    selected_user(newval) {
      console.log(newval);
    },
  },

  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_users();
        await this.get_products();
        this.selected_user = null;
        this.item = {
          user_id: null,
          price_id: null,
          payment_method: null,
          quantity: 1,
        };
      } catch (error) {
        console.log(error);
      }
    },

    async get_users() {
      try {
        this.busy = true;

        let res = await this.axios.get("/api/subscriptions/admingetusers");
        this.busy = false;
        console.log(res.data.users);
        if (!res.data.success) throw new Error(res.data.message);
        this.users = res.data.users;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Users could not be fetched");
      }
    },

    async get_products() {
      try {
        // this.busy = true;

        let res = await this.axios.post("/api/subscriptions/admingetprices", { type: "one_time" });
        // this.busy = false;
        console.log(res.data.products);
        if (!res.data.success) throw new Error(res.data.message);
        this.products = res.data.products;
      } catch (error) {
        // this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Plans could not be fetched");
      }
    },

    async buy_sessions() {
      try {
        if (this.busy) return;
        if (!this.item.user_id || !this.item.price_id || !this.item.quantity) throw new Error("Validation Error: Fill in all the fields to continue");
        this.busy = true;

        // validating values

        const res = await this.axios.post("/api/subscriptions/adminbuysessions", { ...this.item });
        this.busy = false;
        console.log(res.data);

        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", "Sessions Updated Successfully");
      } catch (error) {
        this.dialog_delete = false;
        this.busy = false;
        console.log(error.message ? error.message : error);
        this.snackbarShow(true, "Error", error.message ? error.message : error);
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    format_key(key) {
      if (key) return key.replace(/_/gm, " ").trim() + ": ";
      return key;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
.messages-section {
  height: 90%;
}

.messages-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.message {
  border-bottom: 1px solid #e4e3e3;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.messags_text {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.setting-icons {
  cursor: pointer;
}

/* scroll styling */
#message-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.user_bg {
  background-color: #f8f8f8;
}
::v-deep .message_input input {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
}
.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

::v-deep .dialog-select .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-select .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}
::v-deep .dialog-file-input .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-file-input .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-textfield input,
::v-deep .dialog-textfield .v-label {
  font-size: 13px;

  /* Add more CSS styles as needed */
}

::v-deep .script-textarea {
  font-size: 12px;
  font-weight: 500;
  color: red !important;
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: #4a4a4a !important;
  font-weight: 700;
}

.field_icons {
  cursor: pointer;
}
.color_pickers {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

::v-deep .v-input--is-disabled input {
  color: darkgray;
  cursor: not-allowed;
}
input:disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}

.fields-info-tooltip-text {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.fields-info-tooltip-icon {
  /* color: #361f00; */
}
.stripe-div {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 4px;
}
</style>
