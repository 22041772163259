<template>
  <div class="d-flex justify-center job_table">
    <v-data-table
      :headers="headers"
      :items="jobs"
      sort-by="id"
      class="mx-0 px-0 v-sheet v-card fill-height w-100"
      dense
      :options.sync="options"
      :server-items-length="jobs_count"
      :footer-props="{ 'items-per-page-options': [10, 20, 30] }"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="white" class="my-2" small elevation="2" @click="initialize">
            <v-icon class="mr-1" small>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <!-- started slot -->
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ format_datetime(item.createdAt) }}</span>
      </template>

      <!-- last_activity slot -->
      <template v-slot:[`item.updatedAt`]="{ item }">
        <span>{{ format_datetime(item.updatedAt) }}</span>
      </template>

      <!-- Empty table slot -->
      <template v-slot:no-data>
        <div>No jobs found for this chatbot</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "JobsTable",
  props: ["chatbot_id", "new_job"],
  data: () => {
    return {
      jobs: [],
      jobs_count: 0,
      headers: [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Status", align: "start", sortable: true, value: "status" },
        { text: "Created", align: "start", sortable: true, value: "createdAt" },
        { text: "Updated", align: "start", sortable: true, value: "updatedAt" },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      requestSent: false,
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    // new fields
    options() {
      this.get_jobs();
    },
    new_job(newval) {
      if (newval && typeof newval == "object") {
        this.jobs.unshift(Object.assign({}, newval));
        this.jobs.pop();
      }
    },
  },
  async mounted() {
    console.log("chatbot_id: ", this.chatbot_id);
    await this.initialize();
  },

  methods: {
    async initialize() {
      this.options.page = 1;
      await this.get_jobs();
    },
    async get_jobs() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.post("/api/chatbots/getjobs", { chatbot_id: this.chatbot_id, options: this.options });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error("Jobs could not be loaded");
        this.jobs = res.data.jobs;
        this.jobs_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        // this.setSnackbar(true, "Error", "An error occuered while trying to get sessions - kindly check your internet connection");
      }
    },
    format_datetime(dt) {
      return moment(dt).format("YYYY-MM-DD HH:mm:ss ");
    },
  },
};
</script>
<style scoped>
.w-100 {
  width: 100%;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
.job_table .v-btn {
  text-transform: capitalize;
  /* min-width: 200px; */
  float: right;
}
</style>
