<template>
  <div class="w-100">
    <template v-for="key of Object.keys(fields)">
      <template v-if="show_field(fields[key].show)">
        <!-- Case: input type is text -->
        <div v-if="fields[key].input == 'text'" :key="key" class="d-flex flex-row align-baseline justify-space-around my-1">
          <v-text-field v-model="fields[key].value" :label="fields[key].label" :type="fields[key].type" :min="fields[key].input_data.min" :max="fields[key].input_data.max" class="small-font lc-input-fields" hide-details dense solo :disabled="fields[key].disabled">
            <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
              </template>
              <span v-html="fields[key].info"></span>
            </v-tooltip>
          </v-text-field>
          <v-checkbox v-if="fields[key].show_include" v-model="fields[key].include" small class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
          <div v-if="fields[key].input_data.padding_right" class="mx-4" slot="append"></div>
        </div>

        <!-- Case: input type is text -->
        <div v-if="fields[key].input == 'textarea'" :key="key" class="d-flex flex-row align-baseline justify-space-around my-1">
          <v-textarea v-model="fields[key].value" :label="fields[key].label" :rows="fields[key].input_data.rows" class="small-font lc-input-fields" hide-details dense solo> </v-textarea>
          <v-checkbox v-if="fields[key].show_include" v-model="fields[key].include" small class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
          <div v-if="fields[key].input_data.padding_right" class="mx-4" slot="append"></div>
        </div>

        <!-- case: input type is combobox -->
        <div v-if="fields[key].input == 'combobox'" :key="key" class="d-flex flex-row align-baseline justify-space-around pb-1">
          <v-combobox :label="fields[key].label" v-model="fields[key].value" clearable dense multiple small-chips solo class="lc-input-fields" deletable-chips hide-details>
            <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
              </template>
              <span>{{ fields[key].info }}</span>
            </v-tooltip>
          </v-combobox>
          <v-checkbox v-if="fields[key].show_include" v-model="fields[key].include" class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
          <div v-if="fields[key].input_data.padding_right" class="mx-4" slot="append"></div>
        </div>

        <!-- case: input type is select -->
        <div v-if="fields[key].input == 'select'" :key="key" class="d-flex flex-row align-baseline justify-space-around pb-1">
          <v-select
            v-model="fields[key].value"
            :label="fields[key].label"
            :multiple="fields[key].input_data.multiple"
            :items="fields[key].input_data.items"
            dense
            small-chips
            solo
            class="lc-input-fields"
            hide-details
            :return-object="fields[key].input_data.return_object == true"
            :item-text="fields[key].input_data.item_text"
            :item-value="fields[key].input_data.item_value"
            :disabled="fields[key].disabled"
          >
            <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
              </template>
              <span>{{ fields[key].info }}</span>
            </v-tooltip>
          </v-select>
          <v-checkbox v-if="fields[key].show_include" v-model="fields[key].include" class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
          <div v-if="fields[key].input_data.padding_right" class="mx-4" slot="append"></div>
        </div>

        <!-- case: input type is select -->
        <div v-if="fields[key].input == 'autocomplete'" :key="key" class="d-flex flex-row align-baseline justify-space-around pb-1">
          <v-autocomplete
            v-model="fields[key].value"
            :label="fields[key].label"
            :multiple="fields[key].input_data.multiple"
            :items="fields[key].input_data.items"
            dense
            small-chips
            solo
            class="lc-input-fields"
            hide-details
            :return-object="fields[key].input_data.return_object == true"
            :item-text="fields[key].input_data.item_text"
            :item-value="fields[key].input_data.item_value"
            @change="(v) => on_change_handler(v, fields[key].on_change)"
          >
            <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
              </template>
              <span>{{ fields[key].info }}</span>
            </v-tooltip>
          </v-autocomplete>
          <v-checkbox v-if="fields[key].show_include" v-model="fields[key].include" class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
          <div v-if="fields[key].input_data.padding_right" class="mx-4" slot="append"></div>
        </div>

        <!-- case: input type is checkbox button -->
        <div v-if="fields[key].input == 'checkbox'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-center">
          <v-checkbox
            v-model="fields[key].value"
            :label="fields[key].value ? fields[key].input_data.label_on : fields[key].input_data.label_off"
            @change="(v) => on_change_handler(v, fields[key].on_change)"
            class=""
            color="primary"
            hide-details
            flat
            dense
          >
            <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon style="margin-top: 6px" color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
              </template>
              <span class="my-0 py-0">{{ fields[key].info }}</span>
            </v-tooltip>
          </v-checkbox>
        </div>

        <!-- case: input type is switch button -->
        <div v-if="fields[key].input == 'switch'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-center">
          <v-switch
            v-model="fields[key].value"
            :label="fields[key].value ? fields[key].input_data.label_on : fields[key].input_data.label_off"
            class="my-2 py-0 d-flex flex-row align-center"
            color="primary"
            hide-details
            flat
            dense
          >
            <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
              </template>
              <span class="my-0 py-0">{{ fields[key].info }}</span>
            </v-tooltip>
          </v-switch>
        </div>

        <!-- case: input type is range slider -->
        <div v-if="fields[key].input == 'range_slider'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
          <v-range-slider
            v-model="fields[key].value"
            :label="fields[key].label"
            :min="fields[key].input_data.min"
            :max="fields[key].input_data.max"
            :step="fields[key].input_data.step"
            thumb-label
            hide-details
            class="d-flex flex-row align-baseline justify-start w-100"
          >
            <template v-slot:prepend v-if="fields[key].show_info">
              <v-tooltip color="bssecondary2" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                </template>
                <span>{{ fields[key].info }}</span>
              </v-tooltip>
            </template>
          </v-range-slider>
        </div>

        <!-- case: input type is slider -->
        <div v-if="fields[key].input == 'slider'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
          <v-slider
            v-model="fields[key].value"
            :label="fields[key].label"
            :min="fields[key].input_data.min"
            :max="fields[key].input_data.max"
            :step="fields[key].input_data.step"
            thumb-label
            hide-details
            class="d-flex flex-row align-baseline justify-start w-100"
          >
            <template v-slot:append>
              <template v-if="fields[key].show_info">
                <v-tooltip color="bssecondary2" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                  </template>
                  <span>{{ fields[key].info }}</span>
                </v-tooltip>
              </template>

              <span>{{ fields[key].input_data.append }}</span>

              <div v-if="fields[key].input_data.padding_right" class="mx-4"></div>
            </template>
          </v-slider>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: ["fields"],
  data: () => {
    return {};
  },
  watch: {
    // ranges: {
    //   deep: true,
    //   handler(newval) {
    //     this.$emit("time-updated", newval);
    //   },
    // },
  },
  mounted() {
    try {
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    add_range() {
      this.ranges.push({ dates: [null, null], time: { start: null, end: null } });
    },
    remove_range(index) {
      if (this.ranges.length > 1) this.ranges.splice(index, 1);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    print(v) {
      console.log(v);
    },
    show_field(show) {
      if (typeof show == "boolean") return show;
      else if (typeof show == "function") return show(this.fields);
    },
    on_change_handler(v, fn) {
      if (fn && typeof fn == "function") fn(this.fields, v);
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
  max-width: 100%;
}
</style>
