<template>
  <span>
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="2000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- the visible button in the component -->
    <!-- <v-btn color="white" class="small-font ml-1" style="text-transform: capitalize" @click="show = !show" small elevation="2">
      <v-icon class="mr-1" small>mdi-cloud-upload</v-icon>
      Add Contact
    </v-btn> -->

    <!-- Dialog box with options and triggers-->
    <v-dialog :value="show" max-width="550" persistent hide-overlay transition="dialog-bottom-transition" class="rounded-0 pa-2">
      <v-card>
        <!-- toolbar section -->
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Save Contact</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn small icon dark @click="$emit('dialog-toggled', false)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <!-- content section -->
        <div class="w-100 pa-3">
          <div class="d-flex flex-column justify-center align-center w-100">
            <v-sheet color="bsprimary2" elevation="3" rounded class="ma-1 py-4 colored-sheet w-100">
              <v-row justify="center">
                <v-col cols="12" v-if="dialog_contact">
                  <!-- Text Field -->
                  <div class="d-flex flex-column align-left justify-center px-4">
                    <v-text-field v-model="dialog_contact.phone_number" label="Phone Number" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter phone number in international format. For example +61731320000</span>
                      </v-tooltip>
                    </v-text-field>

                    <v-text-field v-model="dialog_contact.fname" label="Firstname" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter Firstname of contact, this will be used for search and referencing by the agent within calls</span>
                      </v-tooltip>
                    </v-text-field>

                    <v-text-field v-model="dialog_contact.lname" label="Lastname" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter Lastname of contact, this can be used for search and referencing by the agent within calls</span>
                      </v-tooltip>
                    </v-text-field>

                    <v-autocomplete
                      v-model="dialog_contact.batch.id"
                      :items="batches"
                      item-text="name"
                      item-value="id"
                      :loading="batch_loading"
                      label="Batch"
                      class="small-font lc-input-fields"
                      hide-details
                      dense
                      solo
                      small-chips
                    >
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small @click.stop="get_batches">mdi-refresh</v-icon>
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Select an existing batch if contact belongs to a batch, otherwise keep empty. This can be used in search and filters</span>
                      </v-tooltip>
                    </v-autocomplete>
                  </div>

                  <!-- buttons row -->
                  <v-row class="px-4 align-right justify-space-between btns-div" no-gutters>
                    <v-col cols="12" md="12" class="text-right">
                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="set_fields" small elevation="2" :disabled="loading">
                        <v-icon class="mr-1" small>mdi-redo</v-icon>
                        Reset
                      </v-btn>

                      <v-btn
                        color="white"
                        class="small-font ml-1 mt-1 red--text"
                        style="text-transform: capitalize"
                        @click="delete_contact"
                        small
                        elevation="2"
                        v-if="existing_contact"
                        :disabled="loading"
                      >
                        <v-icon color="red" class="mr-1" small>mdi-delete</v-icon>
                        Delete
                      </v-btn>
                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="save_contact" small elevation="2" :loading="loading" :disabled="loading">
                        <v-icon class="mr-1" small>mdi-content-save</v-icon>
                        <span slot="loader">processing...</span>
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import _ from "lodash";
export default {
  name: "BatchUploadDialog",
  props: ["show", "contact", "contacts"],
  data: () => {
    return {
      default_contact: {
        id: null,
        fname: null,
        lname: null,
        phone_number: null,
        batch: { id: null, name: null },
      },
      dialog_contact: null,
      batches: [],
      batch_loading: false,

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      loading: false,
    };
  },
  computed: {
    existing_contact() {
      return this.dialog_contact && this.dialog_contact.id;
    },
  },
  watch: {
    contact: {
      handler(newval) {
        this.set_fields();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_batches();
      } catch (error) {
        console.log(error);
      }
    },
    async get_batches() {
      try {
        this.batch_loading = true;
        const res = await this.axios.get("/api/voicecalling/getallbatches");
        this.batch_loading = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.batches = res.data.batches;
      } catch (error) {
        this.batch_loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", "Batches could not be fetched - Connection Error");
      }
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    set_fields() {
      if (this.contact && this.contact.id) this.dialog_contact = _.cloneDeep(this.contact);
      else this.dialog_contact = _.cloneDeep(this.default_contact);
    },

    async save_contact() {
      try {
        this.loading = true;
        const contact = _.cloneDeep(this.dialog_contact);
        if (!contact.phone_number) throw new Error("Enter contact's phone number to continue");
        if (!/^\+\d{3,15}$/.test(contact.phone_number)) throw new Error("Enter phone number in international format. For example +61731320000");
        // if (!contact.fname) throw new Error("Contact's Firstname is required");

        const res = await this.axios.post("/api/voicecalling/savecontact", { contact });
        this.loading = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", res.data.message);
        this.$emit("contact-updated", contact);
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Contact could not be saved - Connection Error");
      }
    },
    async delete_contact() {
      try {
        this.loading = true;
        const id = this.dialog_contact.id;
        if (!id) throw new Error("Invalid contact selected for deletion");

        const res = await this.axios.post("/api/voicecalling/deletecontact", { id });
        this.loading = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", res.data.message);
        this.$emit("contact-updated", {});
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Contact could not be deleted - Connection Error");
      }
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .btns-div .v-btn {
  width: 150px;
}
</style>
