<template>
  <span>
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="10000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- the visible button in the component -->
    <v-btn color="white" class="small-font ml-1" style="text-transform: capitalize" @click="show = !show" small elevation="2">
      <v-icon class="mr-1" small>mdi-account-multiple-plus</v-icon>
      Add Batch
    </v-btn>

    <!-- Dialog box with options and triggers-->
    <v-dialog :value="show" max-width="550" persistent hide-overlay transition="dialog-bottom-transition" class="rounded-0 pa-2">
      <v-card>
        <!-- toolbar section -->
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Upload Batch File</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn small icon dark @click="show = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <!-- content section -->
        <div class="w-100 pa-3">
          <div class="d-flex flex-column justify-center align-center w-100">
            <v-sheet color="bsprimary2" elevation="3" rounded class="ma-1 py-4 colored-sheet">
              <v-row justify="center">
                <v-col cols="12">
                  <!-- Text Field -->
                  <div class="d-flex flex-column align-left justify-center px-4">
                    <v-text-field v-model="batch.name" label="Batch Name" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter Batch Name, this can be used to identify the contacts in search criteria in future</span>
                      </v-tooltip>
                    </v-text-field>

                    <v-text-field v-model="batch.description" label="Batch Description" class="small-font lc-input-fields" hide-details dense solo>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Enter Batch Description, this can be used to reference the purpose of batch creation</span>
                      </v-tooltip>
                    </v-text-field>

                    <!-- Upload Field -->
                    <v-file-input
                      v-model="batch.file"
                      accept=".csv"
                      label="Select File"
                      class="small-font lc-input-fields"
                      hide-details
                      dense
                      solo
                      prepend-icon=""
                      counter
                      show-size
                      small-chips
                      truncate-length="30"
                    >
                      <v-icon slot="prepend-inner" color="bssecondary2" dense small @click="show = true">mdi-file-outline</v-icon>
                      <v-tooltip color="bssecondary2" bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Select file in CSV format, if you are unsure of the format, download format from the options.</span>
                      </v-tooltip>
                    </v-file-input>
                  </div>

                  <!-- buttons row -->
                  <v-row class="px-4 align-baseline justify-space-between btns-div" no-gutters>
                    <v-col cols="12" md="12">
                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="reset_fields" small elevation="2">
                        <v-icon class="mr-1" small>mdi-redo</v-icon>
                        Reset
                      </v-btn>

                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="get_format" small elevation="2">
                        <v-icon class="mr-1" small>mdi-cloud-download</v-icon>
                        Download Format
                      </v-btn>
                      <v-btn color="white" class="small-font ml-1 mt-1" style="text-transform: capitalize" @click="upload_batch" small elevation="2" :loading="loading" :disabled="loading">
                        <v-icon class="mr-1" small>mdi-cloud-upload</v-icon>
                        <span slot="loader">processing...</span>
                        Upload File
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "BatchUploadDialog",
  data: () => {
    return {
      show: false,
      batch: { name: null, description: "", file: null },

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
      loading: false,
    };
  },
  methods: {
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    reset_fields() {
      this.batch.name = null;
      this.batch.description = null;
      this.batch.file = null;
    },

    async get_format() {
      try {
        const response = await this.axios.get("/api/voicecalling/getsampleformat");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const filename = "format.csv";

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Format could not be downloaded - Connection Error");
      }
    },

    async upload_batch() {
      try {
        this.loading = true;
        if (!this.batch.name) throw new Error("enter batch name to continue");
        if (!this.batch.file) throw new Error("Select a file with right format to continue");

        const formData = new FormData();
        formData.append("file", this.batch.file);
        formData.append("batch_name", this.batch.name);
        formData.append("batch_description", this.batch.description);

        const res = await this.axios.post("/api/voicecalling/uploadbatch", formData, { headers: { "Content-Type": "multipart/form-data" } });
        this.loading = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", res.data.message);
        this.reset_fields();
        this.show = false;
        this.$emit("batch-uploaded", {});
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Format could not be downloaded - Connection Error");
      }
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .btns-div .v-btn {
  width: 150px;
}
</style>
