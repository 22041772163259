const axios = require("axios");
const config = require("../../config/app.json");

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

module.exports.users = {
  state: () => ({
    //   logged in user
    user: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      group: "",
      type: "",
      verified: false,
      image: "",
    },
  }),
  getters: {
    //   get logged in uer
    getUser: (state) => {
      return state.user;
    },
  },
  mutations: {
    //   set logged in user
    ["SET_USER"](state, user) {
      state.user = user;
    },
    //   update logged in user
    ["UPDATE_USER"](state, updatedUser) {
      state.user.firstName = updatedUser.firstName;
      state.user.lastName = updatedUser.lastName;
      state.user.role = updatedUser.role;
      state.user.adminVerified = updatedUser.adminVerified;
      state.user.image = updatedUser.image;
      state.user.assigned_number = updatedUser.assigned_number;
    },
  },
  actions: {
    // setting logged in user
    setUser({ commit }, updatedUser) {
      commit("SET_USER", updatedUser);
    },
    //   update logged in user
    updateUser({ commit }, updatedUser) {
      commit("UPDATE_USER", updatedUser);
    },
    // fetching logged in user
    async fetchUser({ commit }) {
      try {
        let res = await axios.get("/api/users/self");
        commit("SET_USER", res.data.user);
      } catch (error) {
        console.log("error users store: ", error);
        throw new Error("Error fetching user data");
      }
    },

    is_allowed_feature({ state }, key) {
      try {
        if (["admin"].includes(state.user.group)) return true; //allow all access to admins
        if (state.user.subscription.plan.other_allowed_features[key]) return true; // allow if plan has key and its set to true

        return false;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },
};
