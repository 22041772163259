<template>
  <v-sheet color="white" elevation="3" rounded class="mx-2 mt-3">
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="5000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <template>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="campaigns"
        :options.sync="options"
        :server-items-length="campaigns_length"
        item-key="id"
        class="rounded-6 datatable-height"
        :show-select="false"
        :single-select="singleSelect"
        :show-expand="false"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        fixed-header
        dense
        :footer-props="{ 'items-per-page-options': [10, 20, 30] }"
        :loading="table_loading"
        elevation="10"
      >
        <!-- Actions slot -->
        <template v-slot:[`item.actions`]="{ item }">
          <!-- Stop campaign -->
          <v-tooltip color="bssecondary2" left :open-delay="50">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" small class="mr-2" @click="stop_campaign(item)" v-on="on">mdi-stop-circle-outline</v-icon>
            </template>
            <span>Stop Campaign</span>
          </v-tooltip>

          <!-- Load history -->
          <v-tooltip color="bssecondary2" left :open-delay="50">
            <template v-slot:activator="{ on }">
              <v-icon color="primary" small class="mr-2" @click="load_single_item(item)" v-on="on">mdi-arrow-up-bold-circle-outline</v-icon>
            </template>
            <span>Load campaign settings</span>
          </v-tooltip>

          <!-- delete  history -->
          <v-tooltip color="bssecondary2" left :open-delay="50">
            <template v-slot:activator="{ on }">
              <v-icon color="#ff6e63" small class="mr-2" @click="remove_single_item(item)" v-on="on">mdi-delete</v-icon>
            </template>
            <span>Remove campaign from history</span>
          </v-tooltip>
        </template>

        <!-- Date slot -->
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ format_date(item.createdAt) }}
        </template>

        <template v-slot:top>
          <!-- History row -->
          <v-row class="px-4 py-1 align-baseline justify-center" no-gutters>
            <v-col cols="12" md="12" class="mx-0 px-0mr-0 py-0">
              <h4 class="my-2 mx-0 px-0 text-left pr-2 w-100 d-flex flex-row align-baseline justify-space-between">
                <span>Campaigns</span>
                <v-btn color="white" style="text-transform: capitalize" @click="load_campaigns()" small elevation="2">
                  <v-icon class="mr-1" small>mdi-reload</v-icon>
                  Refresh
                </v-btn>
              </h4>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </template>
  </v-sheet>
</template>

<script>
export default {
  name: "CampaignTable",
  props: [],
  data() {
    return {
      // table
      headers: [
        { text: "Name", value: "name", align: "start", sortable: true },
        { text: "Contacts", value: "contacts", align: "start", sortable: true },
        { text: "Created", value: "createdAt", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      campaigns: [],
      campaigns_length: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      // table expansion
      expanded: [],
      singleExpand: false,

      // Table selection
      singleSelect: false,
      selected: [],
      table_loading: false,

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
    };
  },
  watch: {
    options() {
      this.load_campaigns();
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.load_campaigns();
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while initializing page");
      }
    },

    async load_campaigns() {
      try {
        // checking for other requests
        if (this.table_loading) return;
        this.table_loading = true;
        let res = await this.axios.post("/api/voicecalling/loadcampaigns", { options: this.options });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.campaigns = res.data.campaigns;
        this.campaigns_length = res.data.count;
      } catch (error) {
        this.table_loading = false;
        console.log(error);
        this.snackbarShow(true, "Error", "campaigns could not be loaded: check your internet connection");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    async load_single_item(h) {
      try {
        this.$emit("load-campaign", h);
      } catch (error) {
        console.log(error);
      }
    },

    async remove_single_item(item) {
      try {
        this.table_loading = true;
        const id = item.id;
        let res = await this.axios.post("/api/voicecalling/removecampaign", { id });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        await this.load_campaigns();
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", error.message ? error.message : "History could not be removed: check your internet connection");
      }
    },

    async stop_campaign(item) {
      try {
        this.table_loading = true;
        const id = item.id;
        let res = await this.axios.post("/api/voicecalling/stopcampaign", { id });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        await this.load_campaigns();
        this.snackbarShow(true, "Info", res.data.message);
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", error.message ? error.message : "Campaign could not be stopped at this moment - check your internet connection");
      }
    },

    format_date(date) {
      if (!date) return date;
      return date.replace("T", " ").replace(/\.[^.]*$/gi, "");
    },

    visit_link(l) {
      window.open(l, "_blank");
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .sr-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
}
</style>
