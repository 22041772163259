<template>
  <v-row class="justify-center ma-1">
    <!-- Snackbar for small info alerts -->
    <upload-progress-scroller
      :progress_show="progress_show"
      :progress_items="progress_items"
      @update-upload-progress="(v) => (progress_show = v)"
    ></upload-progress-scroller>

    <v-overlay :value="loading" z-index="1000">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
        <span class="py-2 px-2">Processing...</span>
      </v-snackbar>
    </v-overlay>

    <v-snackbar :value="showSnackbar" timeout="-1" app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-1 pa-1">
      <v-card class="px-0 py-5 ma-0 d-flex flex-column justify-space-between align-center" style="min-height: 90vh">
        <v-row class="w-100 pa-0 ma-0">
          <v-col cols="12" sm="12" md="12" class="d-flex justify-end align-end">
            <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" :to="{ name: 'AdminChatbots' }">
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
            <v-btn color="primary" dark text outlined class="py-5 rounded-0 text-capitalize" @click="initialize()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Message Display-->
        <div class="messages-section w-100 d-flex flex-column justify-start align-center">
          <v-card class="w-50" flat>
            <!-- <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <template>
                    <!-- chatbot settings section -->
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3">Chatbot Settings</h4>
                    </v-col>

                    <!-- existing chatbot -->
                    <v-col cols="12" class="py-1">
                      <v-text-field
                        hide-details
                        label="Enter new chatbot name"
                        v-model="chatbot.name"
                        solo
                        dense
                        class="text-sm dialog-textfield"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="chatbot.user_id"
                        :items="users"
                        :item-text="(v) => `${v.firstName} ${v.lastName.replace(/-/g, '')} (${v.email})`"
                        item-value="id"
                        label="Select User"
                        hide-details
                        solo
                        dense
                        class="text-sm dialog-textfield"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="py-1 d-flex flex-row align-center justify-end">
                      <v-btn color="success" small @click="update_admin_chabot" prepend-icon="mdi-link-plus" outlined width="120px">
                        <v-icon right dark class="mr-1 ml-0">mdi-content-save</v-icon>
                        Update
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3">Chatbot Script</h4>
                    </v-col>

                    <v-col cols="12" class="py-1">
                      <v-textarea
                        v-model="deployment_script"
                        rows="4"
                        hide-details
                        dense
                        solo
                        class="script-textarea"
                        readonly
                        no-resize
                        append-icon="mdi-content-copy"
                        @click:append="copyScript"
                      ></v-textarea>
                    </v-col>
                  </template>

                  <!-- ------------------------------------------------------------------------------------------------- -->
                  <template>
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3">Chatbot Demo</h4>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-textarea
                        rows="3"
                        hide-details
                        dense
                        solo
                        class="script-textarea"
                        readonly
                        no-resize
                        append-icon="mdi-content-copy"
                        @click:append="copy_demo_link"
                        label="Demo Link"
                        v-model="demo_link"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="py-1 d-flex flex-row align-center justify-end">
                      <!-- <v-btn color="primary" small @click="copy_demo_link" prepend-icon="mdi-link-plus" outlined width="120px" class="mr-2">
                        <v-icon right dark class="mr-1 ml-0">mdi-content-copy</v-icon>
                        Copy
                      </v-btn> -->
                      <v-btn color="primary" small @click="visit_demo_link" prepend-icon="mdi-link-plus" outlined width="120px">
                        <v-icon right dark class="mr-1 ml-0">mdi-link</v-icon>
                        Visit
                      </v-btn>
                    </v-col>
                  </template>
                  <!-- ------------------------------------------------------------------------------------------------- -->
                  <template>
                    <!-- data upload settings -->
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3">Upload Data</h4>
                    </v-col>

                    <!-- existing chatbot -->
                    <v-col cols="12" sm="12" class="py-0 pb-1">
                      <!-- Data Saving mode -->
                      <v-select
                        hide-details
                        v-model="upload_settings.remove_existing_data"
                        :items="saving_options"
                        label="Select Data Option"
                        solo
                        dense
                        class="text-sm dialog-select"
                      >
                        <template #append-outer>
                          <v-tooltip bottom right color="#001536">
                            <template v-slot:activator="{ on }">
                              <v-icon class="fields-info-tooltip-icon" v-on="on">mdi-information-outline</v-icon>
                            </template>
                            <div class="fields-info-tooltip-text">
                              Choose wether to keep the existing data or override it to start fresh.
                              <br />
                              Note: In case your delete the old data, it will not be regenerated.
                            </div>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- selecting existing o new chatbot -->
                    <v-col cols="12" sm="12" class="py-0 pb-1">
                      <v-select
                        hide-details
                        v-model="upload_settings.type"
                        :items="upload_type_options"
                        label="Select Upload Type..."
                        solo
                        dense
                        class="text-sm dialog-select"
                      >
                        <template #append-outer>
                          <v-tooltip bottom right color="#001536">
                            <template v-slot:activator="{ on }">
                              <v-icon class="fields-info-tooltip-icon" v-on="on">mdi-information-outline</v-icon>
                            </template>
                            <div class="fields-info-tooltip-text">Select upload type, fields on bottom will depend on the option you choose here.</div>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- case: uplaod file -->
                    <v-col cols="12" class="py-1">
                      <v-file-input
                        v-if="upload_settings.type == 'file'"
                        v-model="file"
                        label="Upload file"
                        accept=".zip"
                        solo
                        dense
                        class="text-sm dialog-file-input"
                        counter
                        show-size
                        prepend-icon="mdi-file-arrow-up-down-outline"
                      >
                        <template #append-outer>
                          <v-tooltip bottom right color="#001536">
                            <template v-slot:activator="{ on }">
                              <v-icon class="fields-info-tooltip-icon" v-on="on">mdi-information-outline</v-icon>
                            </template>
                            <div class="fields-info-tooltip-text">
                              - Click on the field & select your file here.
                              <br />
                              - File should be (.zip) format containing all the required data files in it.
                              <br />
                              <br />
                              - Contained data files should be one of the these formats:
                              <br />
                              - Documents: pdf, doc, docx, txt, md
                              <br />
                              - Spreedsheet: csv, xlsx
                              <br />
                              - Presentation: pptx
                              <br />
                              - Structured: json
                              <br />
                              <br />
                              - We are actively adding in more data formtas as per feedback from users.
                            </div>
                          </v-tooltip>
                        </template>
                      </v-file-input>

                      <!-- case:  website link -->
                      <template v-if="upload_settings.type == 'scrape'">
                        <!-- website type -->
                        <v-select
                          hide-details
                          v-model="upload_settings.website_type"
                          :items="[
                            { text: 'Static Website', value: 'static' },
                            { text: 'SPA (Single Page Applications)', value: 'spa' },
                          ]"
                          label="Select Website Type..."
                          solo
                          dense
                          class="text-sm dialog-select"
                        >
                          <template #append-outer>
                            <v-tooltip bottom right color="#001536">
                              <template v-slot:activator="{ on }">
                                <v-icon class="fields-info-tooltip-icon" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <div class="fields-info-tooltip-text">
                                - Select type of website as per website structure.
                                <br />
                                - If you are unsure of website type, select static.
                                <br />
                                - SPAs refer to websites made with tech such as react, angular or vue etc.
                              </div>
                            </v-tooltip>
                          </template>
                        </v-select>

                        <v-text-field
                          label="Enter website link"
                          v-model="upload_settings.website_link"
                          solo
                          dense
                          class="text-sm dialog-textfield my-2"
                          prepend-icon="mdi-link-plus"
                          hide-details
                        >
                          <template #append-outer>
                            <v-tooltip bottom right color="#001536">
                              <template v-slot:activator="{ on }">
                                <v-icon class="fields-info-tooltip-icon" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <div class="fields-info-tooltip-text">
                                - Enter complete website link including protocol (e.g. https), hostname(e.g. abc.com) & path(e.g. /path/to/page).
                                <br />
                                - Example Input: https://www.busi.chat. (NOT busi.chat)
                                <br />
                                - Any subdomain or external links found will not be visited unless explicitly added in allowed domains.
                                <br />
                                - Subdomains with www.website_name are an exception to the above rule & will be visited.
                              </div>
                            </v-tooltip>
                          </template>
                        </v-text-field>

                        <v-text-field
                          label="Enter allowed domains, multiple domains should be separated by commas"
                          v-model="upload_settings.allowed_domains"
                          solo
                          dense
                          class="text-sm dialog-textfield my-2"
                          prepend-icon="mdi-web-check"
                          hide-details
                        >
                          <template #append-outer>
                            <v-tooltip bottom right color="#001536">
                              <template v-slot:activator="{ on }">
                                <v-icon class="fields-info-tooltip-icon" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <div class="fields-info-tooltip-text">
                                - Enter multiple (comma separated) subdomains you want the data from (without protocol such as https).
                                <br />
                                - All found links from allowed domains & subdomains will be visited.
                                <br />
                                Example:
                                <br />
                                - Website Link: https://busi.chat/
                                <br />
                                - Allowed Domains: blog.busi.chat, demo.busi.chat, subdomain.busi.chat
                              </div>
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </template>
                    </v-col>

                    <v-col cols="12" class="py-1 pb-3 d-flex flex-row align-center justify-end">
                      <v-btn color="primary" small @click="upload_data" prepend-icon="mdi-link-plus" outlined width="120px" :disabled="busy_upload_button">
                        <v-icon right dark class="mr-1 ml-0">mdi-cloud-upload</v-icon>
                        Upload
                      </v-btn>
                    </v-col>
                  </template>

                  <!------------------------------------------------------ CUSTOM FIELDS SECTION START ------------------------------------------------------>
                  <v-col cols="12" sm="12" class="pb-0">
                    <h4 class="pb-3">Custom Data (Public)</h4>
                  </v-col>

                  <template v-for="key of Object.keys(settings.fields)">
                    <v-col cols="12" class="py-1" :key="key">
                      <label for=""></label>
                      <v-text-field
                        hide-details
                        :prefix="format_key(key)"
                        v-model="settings.fields[key]"
                        solo
                        dense
                        class="text-sm dialog-textfield text-capitalize"
                      ></v-text-field>
                    </v-col>
                  </template>

                  <!-- Extra fields -->
                  <template>
                    <v-col cols="12" sm="12" class="pb-0">
                      <h4 class="pb-3 d-flex">
                        Additional Fields
                        <v-icon right dark class="mr-2 ml-2 field_icons" color="success" @click="settings.custom_fields.push({ key: '', value: '' })">
                          mdi-plus-box
                        </v-icon>
                      </h4>
                    </v-col>
                    <template v-for="(cf, index) of settings.custom_fields">
                      <v-col cols="12" class="py-1 d-flex" :key="index">
                        <v-text-field hide-details v-model="cf.key" label="Key" solo dense class="pr-1 text-sm dialog-textfield text-capitalize"></v-text-field>
                        <v-text-field
                          hide-details
                          v-model="cf.value"
                          label="Value"
                          solo
                          dense
                          class="pr-1 text-sm dialog-textfield text-capitalize"
                        ></v-text-field>
                        <v-icon right dark small class="mr-1 ml-0 field_icons" color="red" @click="settings.custom_fields.splice(index, 1)">
                          mdi-trash-can-outline
                        </v-icon>
                      </v-col>
                    </template>
                  </template>

                  <v-col cols="12" class="py-3 d-flex flex-row align-center justify-end">
                    <v-btn color="green" small @click="update_user_settings(['fields'])" outlined width="120px">
                      <v-icon right dark class="mr-1 ml-0">mdi-content-save</v-icon>
                      Save
                    </v-btn>
                  </v-col>
                  <!------------------------------------------------------ CUSTOM FIELDS SECTION END ------------------------------------------------------>

                  <!---------------------------------------------------- CUSTOM UI SETTINGS START ---------------------------------------------------->

                  <v-col cols="12" sm="12" class="pb-0">
                    <h4 class="pb-3">Widget Style Customization</h4>
                  </v-col>

                  <template v-for="d of style_defs">
                    <v-col cols="12" class="py-1" :key="d.key" v-if="d.type == 'text'">
                      <v-text-field
                        hide-details
                        :prefix="d.label"
                        v-model="settings.style[d.key]"
                        solo
                        dense
                        class="text-sm dialog-textfield text-capitalize"
                        :disabled="d.disabled"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="py-1" :key="d.key" v-else-if="d.type == 'color'">
                      <v-menu v-model="d.open" :close-on-content-click="false" offset-y offset-x :disabled="d.disabled" transition="scale-transition">
                        <template v-slot:activator="{}">
                          <v-text-field
                            v-model="settings.style[d.key]"
                            @click="d.open = !d.open"
                            readonly
                            hide-details
                            :prefix="d.label"
                            solo
                            dense
                            class="text-sm dialog-textfield text-capitalize"
                          ></v-text-field>
                        </template>
                        <v-card>
                          <v-color-picker v-model="settings.style[d.key]" @input="(v) => (d.value = v)" swatches-max-height="200" class="color_pickers" />
                        </v-card>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" class="py-1" :key="d.key" v-else-if="d.type == 'select'">
                      <v-select
                        v-model="settings.style[d.key]"
                        :items="style_options[d.key]"
                        hide-details
                        :prefix="d.label"
                        solo
                        dense
                        class="text-sm dialog-textfield text-capitalize"
                      ></v-select>
                    </v-col>
                  </template>
                  <v-col cols="12" class="py-3 d-flex flex-row align-center justify-end">
                    <v-btn color="green" small @click="update_user_settings(['style'])" outlined width="120px">
                      <v-icon right dark class="mr-1 ml-0">mdi-content-save</v-icon>
                      Save
                    </v-btn>
                  </v-col>
                  <!---------------------------------------------------- CUSTOM UI SETTINGS END ---------------------------------------------------->
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const config = require("../../../../config/app.json");
import UploadProgressScroller from "../../../components/UploadProgressScroller.vue";
export default {
  name: "Chatbot",
  components: {
    UploadProgressScroller,
  },
  data: () => ({
    // progress component
    progress_items: [],
    progress_show: false,

    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    busy: false,
    busy_upload_button: false,

    // file upload section

    chatbot: {
      name: "",
      user_id: null,
    },
    settings: {
      fields: {
        assistant_role: "",
        assistant_name: "",
        company_name: "",
        company_contact: "",
        company_email: "",
        company_address: "",
        company_industry: "",
        company_region: "",
      },
      custom_fields: [],
      style: {
        widget_font: `"Segoe UI", Tahoma, Geneva, Verdana, sans-serif;`,
        header_logo: "./images/logo.png",
        header_title: "Busichat",
        header_color: "#00012CFF",

        user_message_color: "#00012CFF",
        user_message_text_color: "#fff",
        bot_message_color: "#f1f0f0",
        bot_message_text_color: "#000",

        input_placeholder_text: "Ask your question here",
        welcome_message: "Greetings!! how may I help you today?",

        footer_button_icon: "./images/send3.svg",
        footer_button_color: "initial",
        footer_logo: "https://bot.busi.chat/images/logo.png",

        bottom: "60px",
        right: "20px",
      },
    },

    style_defs: [
      { key: "widget_font", value: "", label: "Widget Font: ", type: "select", open: false, disabled: false },
      { key: "header_logo", value: "", label: "header logo: ", type: "text", open: false, disabled: true },
      { key: "header_title", value: "", label: "header title: ", type: "text", open: false, disabled: false },
      { key: "header_color", value: "", label: "header color: ", type: "color", open: false, disabled: false },

      { key: "user_message_color", value: "", label: "user message color: ", type: "color", open: false, disabled: false },
      { key: "user_message_text_color", value: "", label: "user message text color: ", type: "color", open: false, disabled: false },
      { key: "bot_message_color", value: "", label: "bot message color: ", type: "color", open: false, disabled: false },
      { key: "bot_message_text_color", value: "", label: "bot message text color: ", type: "color", open: false, disabled: false },

      { key: "input_placeholder_text", value: "", label: "Input Placeholder text: ", type: "text", open: false, disabled: false },
      { key: "welcome_message", value: "", label: "Greeting Message: ", type: "text", open: false, disabled: false },

      { key: "footer_button_icon", value: "", label: "footer button icon: ", type: "text", open: false, disabled: true },
      { key: "footer_button_color", value: "", label: "footer button color: ", type: "color", open: false, disabled: false },
      { key: "footer_logo", value: "", label: "footer logo: ", type: "text", open: false, disabled: true },

      { key: "bottom", value: "", label: "Distance bottom: ", type: "text", open: false, disabled: false },
      { key: "right", value: "", label: "Distance right: ", type: "text", open: false, disabled: false },
    ],

    style_options: {
      widget_font: ["Arial", "Helvetica", "Roboto", "Open Sans", "Lato", `"Segoe UI", Tahoma, Geneva, Verdana, sans-serif`],
    },

    // chatgpt form
    upload_dialog: false,
    upload_settings: {
      chatbot_id: null,
      remove_existing_data: false,
      type: "scrape",
      website_link: null,
      allowed_domains: null,
      website_type: "static",
    },
    file: null,

    chatbot_options: [
      { text: "Existing", value: true },
      { text: "New", value: false },
    ],
    saving_options: [
      { text: "Remove Existing Data", value: true },
      { text: "Add To Existing Data", value: false },
    ],
    upload_type_options: [
      { text: "File Upload (.zip)", value: "file" },
      { text: "Website (Link)", value: "scrape" },
    ],

    deployment_script: null,
    demo_link: null,
    // supporting data
    users: [],
  }),
  computed: {
    total_tokens() {
      let tokens = 0;
      for (let m of this.messages) if (m.tokens) tokens += m.tokens;
      return tokens;
    },
    loading() {
      return this.busy;
    },
  },
  watch: {
    chatbot() {
      //   this.set_defaults();
      this.setScript();
      this.set_demo_link();
    },
  },
  async mounted() {
    await this.initialize();
    await this.initialize_socket_listeners();
  },
  methods: {
    async initialize() {
      try {
        await this.get_user_chabot();
        await this.get_users();
      } catch (error) {
        console.log(error);
      }
    },

    async get_user_chabot() {
      try {
        this.busy = true;
        const id = this.$route.params.chatbot_id;
        let res = await this.axios.post("/api/chatbots/getadminchatbot", { id });
        this.busy = false;
        console.log(res.data.chatbot);
        if (!res.data.success) throw new Error(res.data.message);
        this.chatbot = res.data.chatbot;

        const settings = res.data.chatbot.settings;
        if (settings) {
          if (settings.fields) for (let k of Object.keys(this.settings.fields)) this.settings.fields[k] = settings.fields[k] || "";
          if (settings.style) for (let k of Object.keys(this.settings.style)) this.settings.style[k] = settings.style[k] || "";
          if (settings.custom_fields) this.settings.custom_fields = settings.custom_fields;
        }
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },

    async get_users() {
      try {
        // if (this.requestSent) return;

        // this.requestSent = true;
        let res = await this.axios.get("/api/chatbots/getadminusers");
        // this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error("Users could not be loaded");
        this.users = res.data.users;
        console.log(this.users);
      } catch (error) {
        // this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", "An error occuered while trying to get users - kindly check your internet connection");
      }
    },

    // update chatbot
    async update_admin_chabot() {
      try {
        if (!this.chatbot.id || !this.chatbot.id || !this.chatbot.name) throw new Error("Invalid chatbot...");

        this.busy = true;
        const res = await this.axios.post("/api/chatbots/updateadminchatbot", { chatbot: this.chatbot });
        this.busy = false;
        console.log(res.data.chatbot);
        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", res.data.message);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },
    // update chatbot settings
    async update_user_settings(update_keys) {
      try {
        // validations: chatbot
        if (!this.chatbot.id || !this.settings || !this.settings.fields) throw new Error("Invalid chatbot...");

        // validations: settings
        const fields = Object.assign({}, this.settings.fields);
        const style = Object.assign({}, this.settings.style);
        let custom_fields = Object.assign([], this.settings.custom_fields);

        // validations: custom fields => validation & sanitization
        let temp = [];
        for (let cf of custom_fields) if (cf.key.trim() && cf.value.trim()) temp.push(cf);
        custom_fields = temp;

        if (!update_keys.length) throw new Error("No fields to update");
        console.log("fields: ", fields);
        console.log("custom fields: ", custom_fields);
        console.log("styles: ", style);

        this.busy = true;
        const res = await this.axios.post("/api/chatbots/updateadminchatbotsettings", {
          chatbot: this.chatbot,
          settings: { fields, custom_fields, style },
          update_keys,
        });
        this.busy = false;
        console.log(res.data);

        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", res.data.message);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error updating chatbot settings.");
      }
    },

    // upload user data

    async initialize_socket_listeners() {
      try {
        const socket = this.$store.state.socket.socket;
        if (!socket) await new Promise((r) => setTimeout(r, 3000));

        socket.on("busichat_upload_started", async (data) => {
          this.busy_upload_button = true;
          this.progress_show = true;
          this.progress_items = [];
          this.progress_items.push(data);
        });
        socket.on("busichat_upload_processing", (data) => {
          this.busy_upload_button = false; //for page refresh and if loading continues
          this.progress_show = true;
          this.progress_items.push(data);
        });
        socket.on("busichat_upload_completed", (data) => {
          this.busy_upload_button = false;
          this.progress_items.push(data);
        });
        socket.on("busichat_upload_error", (data) => {
          this.busy_upload_button = false;
          this.progress_items.push(data);
        });
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Connection could not be established");
      }
    },

    async set_defaults() {
      this.upload_settings = {
        chatbot_id: null,
        remove_existing_data: false,
        type: "scrape",
        website_link: null,
        allowed_domains: null,
        website_type: "static",
      };
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    async upload_data() {
      try {
        this.showSnackbar = false;
        let settings = Object.assign({}, this.upload_settings);
        settings.socket_id = this.$store.state.socket.id;
        settings.chatbot_id = this.chatbot.id;
        console.log(settings.socket_id);
        const urlRegex = /(https?:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5})(:[0-9]{1,5})?(\/.*)?/i;
        const domain_regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;

        // validating data
        if (!settings.chatbot_id) throw new Error("Invalid chatbot selected...");
        if (settings.remove_existing_data == null) throw new Error("Select data writing type...");
        if (settings.type == "file" && !this.file) throw new Error("Select a file to upload.");
        if (settings.type == "scrape" && (!settings.website_link || !urlRegex.test(settings.website_link)))
          throw new Error("Kindly enter a valid URL to continue.");

        // validating allowed domains
        if (settings.type == "scrape" && settings.allowed_domains && settings.allowed_domains.trim()) {
          const domains = settings.allowed_domains.split(",");
          settings.allowed_domains = [];
          if (!domains || !domains.length) throw new Error("Invalid domain name | Allowed Domains | Example: busi.chat, example.com");
          for (const d of domains) {
            if (!domain_regex.test(d.trim())) throw new Error("Invalid domain name | Allowed Domains | Example: busi.chat, example.com");
            settings.allowed_domains.push(d.trim());
          }
        }

        // preparing for upload
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("settings", JSON.stringify(settings));
        this.busy = true;

        // sending request
        const res = await this.axios.post("/api/openai/uploaduserdata", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.busy = false;
        console.log(res.data);
        // this.snackbarShow(true, "Info", res.data.message);
        if (!res.data.success) throw new Error(res.data.message);

        // this.closeDialog();
        // response (socket?)
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Data could not be uploaded");
      }
    },

    setScript() {
      try {
        if (!this.chatbot || !this.chatbot.hash) return;

        const hash = this.chatbot.hash;
        const src = `${config.CHATBOT_STATIC_SCRIPT_URL}/chatbot.js`;

        // vuejs does not allow direct script creation => working around with vanilla js
        let script = document.createElement("script");
        script.setAttribute("src", src);
        script.setAttribute("bot", hash);
        script = script.outerHTML;

        this.deployment_script = script;
        this.show_script = !this.show_script;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Select a chatbot to show script");
      }
    },
    async copyScript() {
      try {
        if (!this.deployment_script) return;
        await navigator.clipboard.writeText(this.deployment_script);
        this.snackbarShow(true, "Script Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },

    set_demo_link() {
      if (!this.chatbot.hash) return;
      const url = config.API_URL;
      this.demo_link = url + "/api/openai/demo?bot=" + this.chatbot.hash;
    },

    async copy_demo_link() {
      try {
        if (!this.demo_link) throw new Error("Invalid demo link - try refreshing the page");
        await navigator.clipboard.writeText(this.demo_link);
        this.snackbarShow(true, "Link Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },

    visit_demo_link() {
      try {
        if (!this.demo_link) throw new Error("Invalid demo link - try refreshing the page");
        window.open(this.demo_link, "_blank");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Could not open the link, kindly try doing it manually");
      }
    },
    format_key(key) {
      if (key) return key.replace(/_/gm, " ").trim() + ": ";
      return key;
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
.messages-section {
  height: 90%;
}

.messages-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.message {
  border-bottom: 1px solid #e4e3e3;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.messags_text {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.setting-icons {
  cursor: pointer;
}

/* scroll styling */
#message-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.user_bg {
  background-color: #f8f8f8;
}
::v-deep .message_input input {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
}
.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

::v-deep .dialog-select .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-select .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}
::v-deep .dialog-file-input .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-file-input .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-textfield input,
::v-deep .dialog-textfield .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .script-textarea {
  font-size: 12px;
  font-weight: 500;
  color: red !important;
}
::v-deep .v-text-field__prefix {
  font-size: 12px;
  color: #4a4a4a !important;
  font-weight: 700;
}

.field_icons {
  cursor: pointer;
}
.color_pickers {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

::v-deep .v-input--is-disabled input {
  color: darkgray;
  cursor: not-allowed;
}
input:disabled {
  background-color: lightgray;
  color: darkgray;
  cursor: not-allowed;
}

.fields-info-tooltip-text {
  font-size: 13px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.fields-info-tooltip-icon {
  /* color: #361f00; */
}
</style>
