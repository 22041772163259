import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import DashboardMain from "../views/dashboard/Main.vue";

// Auth Views & Components
import Account from "../views/auth/Main.vue";
import Login from "../views/auth/Login.vue";
// import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/Forgot.vue";
import Logout from "../views/auth/Logout.vue";
import RegisterConfirmEmail from "../views/auth/RegisterConfirmEmail.vue";
import ForgotEmail from "../views/auth/ForgotEmail.vue";
import PendingVerification from "../views/auth/PendingVerification.vue";
import PendingAdminVerification from "../views/auth/PendingAdminVerification.vue";

// Property Listings Views & Components
import Users from "../views/dashboard/users/Main.vue";
import Notifications from "../views/dashboard/users/Notifications.vue";

// Users Views & Components
import ChangeSettings from "../views/dashboard/users/ChangeSettings.vue";
import ChangePassword from "../views/dashboard/users/ChangePassword.vue";
import ChangeUserSettings from "../views/dashboard/users/ChangeUserSettings.vue";
import ViewActivity from "../views/dashboard/users/ViewActivity.vue";

// listing scribe pages
import AccessByEmail from "../views/auth/AccessByEmail";
import AccessByEmailVerify from "../views/auth/AccessByEmailVerify.vue";

// blog writing components
import ChatDemo from "../views/dashboard/home/ChatDemo";
import Chatbots from "../views/dashboard/home/Chatbots";
import Chatbot from "../views/dashboard/home/Chatbot";
import Chatbot2 from "../views/dashboard/home/Chatbot2";

import Sessions from "../views/dashboard/home/SessionsList";
import Session from "../views/dashboard/home/Session";

import AdminChatbots from "../views/dashboard/home/AdminChatbots";
import AdminChatbot from "../views/dashboard/home/AdminChatbot";

import AdminSubMain from "../views/dashboard/subscriptions_admin/Main";
import AdminSubCreate from "../views/dashboard/subscriptions_admin/CreateSubscription";
import AdminSubUpdate from "../views/dashboard/subscriptions_admin/UpdateSubscription";
import AdminSubDelete from "../views/dashboard/subscriptions_admin/DeleteSubscription";
import AdminBuySessions from "../views/dashboard/subscriptions_admin/BuySessions";

// user subscription routes
import UserSubMain from "../views/dashboard/subscriptions_users/Main";
import UserSubUpdate from "../views/dashboard/subscriptions_users/UpdateSubscription";
import ViewStatus from "../views/dashboard/subscriptions_users/ViewStatus";

// voice calling features
import VoiceDemo from "../views/dashboard/voice/VoiceDemo.vue";
import VoiceDemoAdmin from "../views/dashboard/voice/VoiceDemoAdmin.vue";
import BatchCallDemoAdmin from "../views/dashboard/voice/BatchCallDemoAdmin.vue";
import CallLogs from "../views/dashboard/voice/CallLogs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      { path: "login", name: "Login", component: Login },
      // { path: "register", name: "Register", component: Register },
      { path: "forgotpassword", name: "Forgot Password", component: ForgotPassword },
      { path: "logout", name: "Logout", component: Logout },
      // { path: "registerconfirm", name: "RegisterConfirmEmail", component: RegisterConfirmEmail },
      { path: "forgotemail", name: "ForgotEmail", component: ForgotEmail },
      // { path: "pendingverification", name: "PendingVerification", component: PendingVerification },
      // { path: "PendingAdminVerification", name: "PendingAdminVerification", component: PendingAdminVerification },

      { path: "accessbyemail", name: "AccessByEmail", component: AccessByEmail },
      { path: "accessbyemailverify", name: "AccessByEmailVerify", component: AccessByEmailVerify },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardMain,
    children: [
      {
        path: "chatdemo",
        name: "ChatDemo",
        component: ChatDemo,
        meta: { page: "Dashboard - Admin Chat" },
      },
      {
        path: "chatbots",
        name: "Chatbots",
        component: Chatbots,
        meta: { page: "Chatbots" },
      },
      {
        path: "chatbot",
        name: "Chatbot",
        component: Chatbot,
        meta: { page: "Chatbot" },
      },
      {
        path: "chatbot2",
        name: "Chatbot2",
        component: Chatbot2,
        meta: { page: "Chatbot" },
      },
      {
        path: "sessions",
        name: "Sessions",
        component: Sessions,
        meta: { page: "Sessions" },
      },
      {
        path: "session",
        name: "Session",
        component: Session,
        meta: { page: "Session Data" },
      },
      {
        path: "admin/chatbots",
        name: "AdminChatbots",
        component: AdminChatbots,
        meta: { page: "Manage Chatbots" },
      },
      {
        path: "admin/chatbot",
        name: "AdminChatbot",
        component: AdminChatbot,
        meta: { page: "Manage Chatbot" },
      },

      {
        path: "users",
        name: "Users",
        component: Users,
        children: [
          { path: "manageaccount/changesettings", name: "ChangeSettings", component: ChangeSettings, meta: { page: "Edit Account" } },
          { path: "manageaccount/changepassword", name: "ChangePassword", component: ChangePassword, meta: { page: "Reset Password" } },
          { path: "manageusers/changeusersettings", name: "ChangeUserSettings", component: ChangeUserSettings, meta: { page: "Add Or Remove Users" } },
          { path: "manageusers/viewactivity", name: "ViewActivity", component: ViewActivity, meta: { page: "User Activity Logs" } },
          { path: "notifications", name: "Notifications", component: Notifications, meta: { page: "Notifications" } },
        ],
      },
      {
        path: "admin/subscriptions",
        name: "AdminSubMain",
        component: AdminSubMain,
        children: [
          { path: "create", name: "AdminSubCreate", component: AdminSubCreate, meta: { page: "Create Subscription" } },
          { path: "update", name: "AdminSubUpdate", component: AdminSubUpdate, meta: { page: "Update Subscription" } },
          { path: "delete", name: "AdminSubDelete", component: AdminSubDelete, meta: { page: "Delete Subscription" } },
          { path: "buy", name: "AdminBuySessions", component: AdminBuySessions, meta: { page: "Buy Sessions" } },
        ],
      },
      {
        path: "user/subscriptions",
        name: "UserSubMain",
        component: UserSubMain,
        children: [
          { path: "update", name: "UserSubUpdate", component: UserSubUpdate, meta: { page: "Update Subscription" } },
          { path: "status", name: "ViewStatus", component: ViewStatus, meta: { page: "Subscription" } },
        ],
      },
      {
        path: "voicecalling/demo",
        name: "VoiceDemo",
        component: VoiceDemo,
        meta: { page: "AI Voice Agent - outbound" },
      },
      {
        path: "voicecalling/admindemo",
        name: "VoiceDemoAdmin",
        component: VoiceDemoAdmin,
        meta: { page: "AI Voice Agent - outbound (Admin Demo)" },
      },
      {
        path: "voicecalling/batchdemo",
        name: "BatchCallDemoAdmin",
        component: BatchCallDemoAdmin,
        meta: { page: "Batch Calls (Admin Demo)" },
      },
      {
        path: "voicecalling/calllogs",
        name: "CallLogs",
        component: CallLogs,
        meta: { page: "Call Logs" },
      },
    ],
  },
  // independednt pages
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setHeading", to.meta.page);
  next();
});

export default router;
