<template>
  <div class="d-flex flex-row justify-center align-center small-font w-100">
    <v-sheet color="white" elevation="3" rounded class="ma-0 pa-2 w-100">
      <v-row no-gutters>
        <v-col cols="12">
          <call-log-table></call-log-table>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import CallLogTable from "./components/CallLogTable.vue";
export default {
  name: "BatchContactsDialog",
  components: {
    CallLogTable,
  },
  data: () => {
    return {};
  },
  computed: {},
  watch: {},
  async mounted() {},
  methods: {},
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
.read-mode-toolbar {
  height: 50px;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 400;
  font-size: 16px !important;
}

.small-font * {
  font-weight: 500;
  font-size: 14px !important;
}
.w-100 {
  width: 100%;
  max-width: 100%;
}

::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}
.datatable-height {
  height: 100%;
}
::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .btns-div .v-btn {
  width: 150px;
}
</style>
