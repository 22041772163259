<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <text-view-dialog :show="show_read_dialog" :transcripts="dialog_transcripts" :text="dialog_text" @close-read-dialog="(v) => (show_read_dialog = v)"></text-view-dialog>

    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="white" indeterminate></v-progress-circular>
          <!-- <span class="pa-2 text-center" style="line-height: 2em" v-html="loading_quote"></span> -->
        </div>
        <div class="py-2 d-flex align-baseline justify-center">Processing</div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="(v) => (showSnackbar = v)"
      :value="showSnackbar"
      timeout="5000"
      :vertical="true"
      app
      top
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Input Column -->
    <v-col cols="12" md="7" class="pa-0 d-flex">
      <v-sheet color="white" elevation="3" rounded class="mr-md-1 pb-3 mx-0 column-content w-100">
        <!-- Form section -->
        <v-row class="pr-5 pl-2 mt-6 pb-0 align-baseline justify-center" no-gutters>
          <v-col cols="12" md="12">
            <v-row>
              <!-- data column -->
              <v-col cols="12" md="8" class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start">
                <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2 column-content" width="100%" height="100%">
                  <div class="d-flex flex-row align-baseline justify-space-between w-100 ma-0 pa-0">
                    <h5 class="mb-4 text-left">Call Data</h5>
                  </div>
                  <!-- components -->
                  <batch-contacts-dialog ref="contacts_dialog_component" @selected-contacts-updated="(v) => (selected_contacts = v)"></batch-contacts-dialog>
                  <form-generator :fields="fields"></form-generator>
                  <call-transfer-list-form :transfers="transfers"></call-transfer-list-form>
                </v-sheet>
              </v-col>

              <!-- settings column -->
              <v-col cols="12" md="4" class="mr-0 pr-0 py-0 d-flex settings-div">
                <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2 w-100" align="stretch">
                  <div class="w-100 pt-0 d-flex flex-row align-baseline justify-space-between">
                    <h5 class="mb-4 text-left">Call Settings</h5>
                  </div>

                  <!-- components -->
                  <form-generator :fields="setting_fields"></form-generator>

                  <div class="w-100 pt-4 d-flex flex-row flex-wrap align-end justify-end">
                    <v-btn block class="my-1 ml-1 success-btns white--text" @click="submit_request()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-check</v-icon>
                      Schedule Call
                    </v-btn>

                    <v-btn block class="my-1 danger-btns white--text" @click="set_initial_fields()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-reload</v-icon>
                      <span>Reset Data</span>
                    </v-btn>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- History section -->
        <v-row class="mt-0">
          <v-col cols="12">
            <campaign-table ref="campaign_table" @load-campaign="load_campaign"></campaign-table>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <!-- Result column -->
    <v-col cols="12" sm="12" md="5" class="d-flex flex-column justify-center align-baseline my-sm-2 my-md-0 pa-0">
      <v-sheet color="white" elevation="3" rounded class="py-3 px-2 column-content">
        <v-sheet color="bsprimary2" elevation="3" rounded class="px-3 pb-3">
          <div class="d-flex flex-row justify-space-between align-center">
            <h5 class="mx-1 mt-5 text-left">Total Length: {{ prompt_length }}/{{ prompt_max_length }}</h5>
            <h5 class="mx-1 mt-5 text-left d-flex justify-center align-center">
              <v-icon class="mr-1" color="bssecondary2" dense small @click="get_credit()">mdi-refresh</v-icon>
              Remaining Credit: {{ credit }}
            </h5>
          </div>
          <div>
            <h5 class="mx-1 mt-4 text-left">Call Task and Data:</h5>
            <v-textarea v-model="task" rows="25" hide-details dense solo class="my-2 rounded-3"></v-textarea>
          </div>
        </v-sheet>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import FormGenerator from "./components/FormGenerator.vue";
import TextViewDialog from "./components/TextViewDialog.vue";
import BatchContactsDialog from "./components/BatchContactsDialog.vue";
import CallTransferListForm from "./components/CallTransferListForm.vue";
import CampaignTable from "./components/CampaignTable.vue";
import _ from "lodash";

const { call_data, call_settings } = require("./data/data_batch_calls.js");

export default {
  name: "BatchCalls",
  components: {
    FormGenerator,
    TextViewDialog,
    BatchContactsDialog,
    CallTransferListForm,
    CampaignTable,
  },
  data: () => {
    return {
      credit: 0,
      fields: _.cloneDeep(call_data),
      setting_fields: _.cloneDeep(call_settings),
      task: "",

      requestSent: false,

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      show_read_dialog: false,
      dialog_text: "",
      dialog_transcripts: [],

      prompt_max_length: 5000,
      voices: [],

      selected_contacts: [],
      transfers: [],
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
    prompt_length() {
      return this.task.length;
    },
  },
  watch: {
    staff: {
      deep: true,
      handler(newval) {
        console.log("Staff Updated (parent)", newval);
      },
    },
  },
  async mounted() {
    await this.initialize();

    // getting history
    // setInterval(() => {
    //   this.get_credit();
    //   this.loadHistory();
    // }, 3 * 60 * 1000);
  },
  methods: {
    async initialize() {
      try {
        await this.get_voices();
        await this.set_initial_fields();
        await this.get_credit();
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while initializing page");
      }
    },

    async set_initial_fields() {
      try {
        // initializing setting fields
        this.setting_fields.voice.input_data.items = this.voices;

        // initializing call data
        const default_values = {
          campaign_name: "Campaign1",
          agent_name: "Taylor",
          agent_role: "AI Assistant",
          agent_company_name: "Remap.AI",
          first_sentence: "Hi, I'm {{agent_name}}, {{agent_role}} from {{agent_company_name}}, calling to share some information you might find interesting",
        };
        for (const [key, field] of Object.entries(this.fields)) field.value = default_values[key];

        // setting task fields
        this.task = "some task";

        this.transfers = [];
      } catch (error) {
        this.snackbarShow(true, "Error", error.message ? error.message : "Error while setting initial field values");
      }
    },

    async get_credit() {
      try {
        const res = await this.axios.get(`/api/voicecalling/getcredit`);
        if (!res.data.success) throw new Error(res.data.message);
        this.credit = res.data.credit;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Credit could not be fetched");
      }
    },

    async get_voices() {
      try {
        const res = await this.axios.get(`/api/voicecalling/getvoices`);
        if (!res.data.success) throw new Error(res.data.message);
        this.voices = res.data.voices;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Voices could not be fetched");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },

    format_date(date) {
      if (!date) return date;
      return date.replace("T", " ").replace(/\.[^.]*$/gi, "");
    },

    async submit_request() {
      try {
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 100)); // adding a delay for combobox to set its value on loosing focus

        // validating contacts
        let contacts = _.cloneDeep(this.selected_contacts);
        if (!contacts.length) throw new Error("Select atleast one contact to continue sending the campaign");
        contacts = contacts.map((c) => {
          return c.id;
        });

        // validating setting and data fields
        if (!this.fields.campaign_name.value) throw new Error("Enter a campaign name to continue");
        if (!this.task) throw new Error("Assign a task & assosiated data for agent to complete");
        if (this.prompt_length > this.prompt_max_length) throw new Error(`Collective character count can not be longer than ${this.prompt_max_length} characters`);

        // setting fields
        const fields = {};
        for (const [key, o] of Object.entries(this.setting_fields)) fields[key] = o.value;
        for (const [key, o] of Object.entries(this.fields)) fields[key] = o.value;

        // validating call transfer numbers
        let transfers = _.cloneDeep(this.transfers);
        transfers = transfers.filter((t) => t.key || t.value);
        if (!transfers.length) throw new Error("There should be at least one transfer number in case recpient wants to engage with someone");
        for (const t of transfers) {
          if (!t.key) throw new Error("Assign a name or department name to the number for Agent to reference");
          if (!/^\+\d{7,15}$/.test(t.value)) {
            throw new Error("Validation Error: Transfer numbers should be in internation format with  a plus sign (+) and digits only, for example: +61223334444");
          }
        }

        // setting call transfer fields
        const temp = {};
        for (const t of transfers) temp[t.key] = t.value;
        fields.transfer_list = temp;

        // final payload prepration
        const data = {
          contacts,
          fields,
          prompt: this.task,
        };

        const res = await this.axios.post(`/api/voicecalling/sendbatchcalladmin`, data);
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        await new Promise((r) => setTimeout(r, 100));
        this.snackbarShow(true, "Success", res.data.message);
        this.$refs.campaign_table.load_campaigns();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },

    async load_campaign(h) {
      try {
        await this.set_initial_fields();
        console.log(h);
        this.selected_contacts = [];
        const req = h.request;
        if (h.content) this.task = h.content.trim();
        for (const [key, field] of Object.entries(this.setting_fields)) if (![null, undefined].includes(req[key])) field.value = req[key];
        for (const [key, field] of Object.entries(this.fields)) if (![null, undefined].includes(req[key])) field.value = req[key];
        if (req.transfer_list) {
          const temp_list = [];
          for (const [key, value] of Object.entries(req.transfer_list)) temp_list.push({ key, value });
          this.transfers = temp_list;
        }
        this.$refs.contacts_dialog_component.selected = [];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
  max-width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bssuccessbtns-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bssdangerbtns-base) !important;
}

.tooltip-text {
  color: black;
}
</style>
