let company_data = `Company Name: FivePearls Investment Management Company.
Specialization: Offering Shariah-compliant and ethical property-backed preferred equity funds with highly competitive returns.
Target Audience: Muslim communities and customers from diverse backgrounds interested in ethical investment opportunities.
Product: Shariah-compliant and ethical property-backed preferred equity funds.
Summary: FivePearls specializes in Shariah-compliant, ethical property-backed investments, aiming for competitive returns. They guarantee an 8% p.a. fixed return paid monthly, with variable returns up to 8.8% at maturity. Over four years, they've raised $86.4 million, with an average net return of 16.4% p.a., redeeming $35 million and paying out $8 million in profits across eight funds. Their process is simple: evaluate, invest, relax, profit, and repeat, ensuring investments align with Islamic principles.
Prominent People: Shaan Zaidi , Zain Qureshi, Saad Salahuddin, Elizabeth Derrett, Hadi Khan, Jawad Kureshi.`;

const call_objective = `Qualify leads from a database of Muslim and customers from diverse communities.`;

const call_task = `Ensure leads are suitable for Five Pearls' investment offerings.
Manage appointment scheduling by collecting contact details like Name, contact information, and preferred appointment time.`;

const user_fields = {
  callee_name: {
    value: null,
    // value: "Hamza",
    include: true,
    show_include: false,
    label: "Enter recipient's name",
    info: "Enter recipient's name, this will be used within conversation and greetings.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  phone_number: {
    value: null,
    // value: "+14156367177",
    include: true,
    show_include: false,
    label: "Enter recipient's number (Format:+61412345678)",
    info: `Enter recipient's number, this will be used for calling and reference. 
    <br>Expected Format:<br> +61412345678 <br> +91223334444 <br> +61223334444`,
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  agent_name: {
    value: "Fatima",
    include: true,
    show_include: false,
    label: "Enter name of the AI agent",
    info: "Enter name of the AI agent, this will be used by for personal reference.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  agent_role: {
    value: "AI Assistant",
    include: true,
    show_include: false,
    label: "Enter role of the AI agent",
    info: "Enter role of the AI agent, this will be used by AI Agent for reference & character.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  agent_company_name: {
    value: "FivePearls",
    include: true,
    show_include: false,
    label: "Enter name of the AI agent's company",
    info: "Enter name of the AI agent's, this will be used by AI Agent for personal reference & assosiation.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  first_sentence: {
    value: "Hi, My name is Fatima from FivePearls Property Funds, How are you today?",
    include: true,
    show_include: false,
    label: "Enter First Sentence",
    info: "Enter the first sentence AI Agent will use to start the conversation, example could be a greeting or company specific text.",
    show_info: true,
    show: true,
    type: "text",
    input: "text",
    input_data: {
      default: "",
    },
  },
  // task: {
  //   value: call_task,
  //   include: true,
  //   show_include: false,
  //   label: "Define task or instructions for AI Agent",
  //   info: "Enter tasks or instructions you want the agent to complete.",
  //   show_info: true,
  //   show: true,
  //   type: "text",
  //   input: "textarea",
  //   input_data: {
  //     default: "",
  //     rows: 8,
  //   },
  // },
};

const system_fields = {
  voice: {
    value: null,
    include: true,
    show_include: false,
    label: "Select Voice (AUS Female)",
    info: "Voice or accent of Ai Voice Agent",
    show_info: true,
    show: true,
    disabled: true,
    type: "text",
    input: "select",
    input_data: {
      items: [],
      item_text: (o) => `${o.name} (${o.description})`,
      item_value: "name",
      default: "",
      multiple: false,
    },
  },
  wait_for_greeting: {
    value: null,
    include: true,
    show_include: false,
    label: "Wait For Greetings (Yes)",
    info: "Instructurs AI Agent whether the AI speak first or wait for someone else to talk?",
    show_info: true,
    show: true,
    disabled: true,
    type: "boolean",
    input: "select",
    input_data: {
      items: [
        { text: "No", value: false },
        { text: "Yes", value: true },
      ],
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },

  interruption_threshold: {
    value: null,
    include: true,
    show_include: false,
    label: "Interruption (100ms)",
    info: "Interruption Tolerance - The more the number, the lesser interruptions but more silent time for agent to hear",
    show_info: true,
    show: true,
    disabled: true,
    type: "number",
    input: "text",
    input_data: {
      default: "",
      min: 0,
      max: 800,
    },
  },
  record: {
    value: null,
    include: true,
    show_include: false,
    label: "Record Voice (Yes)",
    info: "Enable or disable call recording",
    show_info: true,
    show: true,
    disabled: true,
    type: "boolean",
    input: "select",
    input_data: {
      items: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },
  language: {
    value: null,
    include: true,
    show_include: false,
    label: "Select Language (Eng)",
    info: "Language AI voice Agent will speak in",
    show_info: true,
    show: true,
    disabled: true,
    type: "text",
    input: "select",
    input_data: {
      items: [
        { text: "English", value: "ENG" },
        { text: "Spanish", value: "ESP" },
        { text: "French", value: "FRE" },
        { text: "Polish", value: "POL" },
      ],
      item_text: "text",
      item_value: "value",
      default: "",
      multiple: false,
    },
  },
  max_duration: {
    value: null,
    include: true,
    show_include: false,
    label: "Max Duration (10 mins)",
    info: "Maximum duration of a call after which call will end",
    show_info: true,
    show: true,
    disabled: true,
    type: "number",
    input: "text",
    input_data: {
      default: "",
      min: 1,
      max: 30,
    },
  },
};

module.exports = {
  user_fields,
  system_fields,
  company_data,
  call_objective,
  call_task,
};
