// transfer to socket-store & initialize
const { io } = require("socket.io-client");
const axios = require("axios");
const config = require("../../config/app.json");

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

module.exports.socket = {
  state: () => ({
    socket: null,
    id: null,
  }),
  getters: {
    getSocketio: (state) => {
      return state.socket;
    },
  },

  actions: {
    async initializeSocketio({ state, commit }) {
      const socket = io.connect(config.API_URL);
      state.socket = socket;
      socket.on("connect", () => (state.id = socket.id));
      socket.on("connected", (data) => console.log(data));
    },
  },
};
